import React from 'react';
import styled from 'styled-components';
const SColorSelect=styled.div`
  ${props => props.outline&&`
    text-shadow: -1px -1px 0 ${props.outline}, 1px -1px 0 ${props.outline}, -1px 1px 0 ${props.outline}, 1px 1px 0 ${props.outline};
  `}
`;
const ColorSelect=props => {
  return (
    <SColorSelect {...props} style={{display: 'inline',color: props.color,...props.style}}>
      {props.children}
    </SColorSelect>
  );
};

export default ColorSelect;