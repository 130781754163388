import React from "react";
import Page from "./page";
// import style from "../styles";

import CuratorDashboard from "../components/curatorDashboard";
import { isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

const IsVideosLoaded = (props) => {
  const videos = useSelector(({ firestore: { data } }) => data.videos);
  if (isLoaded(videos)) return props.children;
  return <p>Loading</p>;
};

const CuratorDash = (props) => {
  useFirestoreConnect("videos");
  return (
    <Page>
      <IsVideosLoaded>
        <CuratorDashboard />
      </IsVideosLoaded>
    </Page>
  );
};
export default CuratorDash;
