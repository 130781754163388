import React from "react";

//Page Things
import Page from "./page";
import { Redirect, Link } from "react-router-dom";
import Button from "../components/button";
import Cs from "../components/colorSelect";

//Redux
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { login } from "../redux/user/user.actions";

//Images
import "./login.css";

const mapStateToProps = (state) => {
  return {
    id: state.currentUser.user,
    error: state.currentUser,
  };
};

const Login = connect(mapStateToProps)((props) => {
  const [stateError, setStateError] = React.useState(null);
  const dispatch = useDispatch();
  const emailRef = React.createRef();
  const passwordRef = React.createRef();

  const handleSubmit = () => {
    const email = emailRef.current.value;
    const password = passwordRef.current.value;
    dispatch(login(email, password)).then(() => {
      console.log(props);
      if (props.error) {
        setStateError(props.error.message);
      }
    });
  };

  // const error = props.error.data.msg;
  return (
    <React.Fragment>
      {props.id && <Redirect to="/watch" />}
      <div className="container1 hight-auto">
        <Page defaultFooter defaultHeader={{ relative: true }} Title="Login">
          <div className="row m-0">
            <div className="col-md-6  py-5">
              <div className="left-bg p-4 text-center">
                <h1 className="text-white mt-5">Welcome to NowUs</h1>
                <h6>New to NowUs?</h6>
                <p>
                  Click " <b style={{ letterSpacing: "1px" }}>Register</b> " to
                  get FREE NowUs TV.
                </p>
                <br />
                <h6>Already registered? </h6>
                <p>
                  Click " <b style={{ letterSpacing: "1px" }}>Login</b> " to
                  start watching FREE NowUs TV again.
                </p>
                <h5 className="text-white">Then sit back, relax and watch!</h5>
              </div>
            </div>
            <div className="col-md-6 py-5 my-5">
              <div className="card mx-5 ">
                <div className="card-body my-5">
                  <h4 className="text-center">NowUs</h4>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      ref={emailRef}
                      name="email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      type="password"
                      className="form-control"
                      ref={passwordRef}
                      name="password"
                      placeholder="Password"
                    />
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                      {stateError && (
                        <h6>
                          <Cs color="red">{stateError}</Cs>
                        </h6>
                      )}
                      <Button onClick={handleSubmit}>
                        <p>Login</p>
                      </Button>
                    </div>
                    <div className="form-group col-6 text-right">
                      <Link to="/recoverpassword">Forgot Password ?</Link>
                    </div>
                  </div>
                  <div className="register-link">
                    <Button to="/register">Register</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Page>
      </div>
    </React.Fragment>
  );
});

export default Login;
