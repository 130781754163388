import React from "react";
import Page from "./page";
import PageSection from "../components/pageSection";
import NamedInput from "../components/namedInput";
import Button from "../components/button";
import axios from "axios";
import Cs from "../components/colorSelect";

const RecoverPassword = (props) => {
  const [isSent, setIsSent] = React.useState(false);
  const [error, setError] = React.useState(null);
  const emailRef = React.createRef();

  const handleRecover = (email) => {
    if (!email) {
      return null;
    }
    axios
      .post("https://admin.nowus.com/api/password/forgot-password", { email })
      .then((res) => {
        console.log(res);
        setIsSent(true);
      })
      .catch((err) => {
        console.log(err);
        let msg = err.response.data;
        setError(msg);
      });
  };

  return (
    <Page defaultFooter defaultHeader={{ relative: true }}>
      <PageSection
        style={{
          margin: "4rem auto",
          padding: "3rem",
          background:
            "linear-gradient(180deg, rgba(157, 232, 251, 0.6) 0%, rgba(0, 31, 95, 0.6) 100%)",
          borderRadius: "20px",
          height: "fit-content",
          width: "50%",
        }}
      >
        {!isSent ? (
          <React.Fragment>
            <h4>Reset Account Password</h4>
            <NamedInput ref={emailRef} name="Email" />
            {error && (
              <h6>
                <Cs color="red">{error.message}</Cs>
              </h6>
            )}
            <Button
              onClick={() => {
                handleRecover(emailRef.current.value);
              }}
            >
              <h5>Reset</h5>
            </Button>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h4>Email Sent</h4>
            <p>Check your email to reset your password</p>
          </React.Fragment>
        )}
      </PageSection>
    </Page>
  );
};

export default RecoverPassword;
