const types={
  LOGIN_SUCCESS:'LOGIN_SUCCESS',
  LOGIN_ERROR:'LOGIN_ERROR',
  REGISTER_USER_SUCCESS:'REGISTER_USER_SUCCESS',
  REGISTER_ERROR:'REGISTER_ERROR',
  LOGOUT_SUCCESS:'LOGOUT_SUCCESS',
  UPDATE_USER_SUCCESS:'UPDATE_USER_SUCCESS',
  UPDATE_USER_ERROR:'UPDATE_USER_ERROR',
  PASSWORD_RESET_SUCCESS:'PASSWORD_RESET_SUCCESS',
  CHECK_BILLING_SUCCESS:'CHECK_BILLING_SUCCESS',
  CHECK_BILLING_ERROR:'CHECK_BILLING_ERROR',
  PASSWORD_RESET_ERROR:'PASSWORD_RESET_ERROR',
  RESET_ERROR:'RESET_ERROR'
}

export default types;