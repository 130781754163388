import React from 'react';
import Page from './page';
import style from '../styles';

import IntroF from '../components/fullscreen/introF';
import DividerF from '../components/fullscreen/dividerF';

import DividerBackground from '../resources/dividerbackground.png';
import GroupImg from '../resources/groupofemployees.jpg';
import PointingImg from '../resources/pointingtv.jpg';

const Contact=() => {
  return (
    <Page defaultHeader defaultFooter title="NowUs - Contact">
      <IntroF style={{color: style.constants.colors.white,paddingTop:'100px'}}
        img={GroupImg}
      >
        <h2>Contact Us!</h2>
        <p>We look forward to hearing from you!</p>
      </IntroF>

      <DividerF
        height='8rem'
        img={DividerBackground}
        color={style.constants.colors.white}
      />

      <IntroF
        img={PointingImg}
        side='right'
        bgColor={style.constants.colors.primary}
        sidebarColor={style.constants.colors.secondary}
        style={{color:style.constants.colors.black}}
      >
        <h5>EMAIL (Customer Service) -</h5>
        <p>CUSTOMERSERVICE@NowUs.COM</p>
        <h5>EMAIL (Brands and Influencers) -</h5>
        <p>INFO@NowUs.COM</p>
        <h5>OFFICES: <br /> LOS ANGELES - CHICAGO - NY</h5>
        <p>NowUs LLC
          <br />6060 CENTER DR. 10TH FLOOR
          <br />LOS ANGELES CA, 90045
          <br />NEAR YOUTUBE STUDIOS LA
        </p>
      </IntroF>
      <DividerF
        height='5rem'
        color={style.constants.colors.black}
      />
    </Page>
  );
};

export default Contact;