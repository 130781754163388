import React, { Component } from "react";
import screenfull from "screenfull";
import styled from "styled-components";
// import {channels} from '../config/nowus';
import ReactPlayer from "react-player";
import { FaPlay, FaPause, FaCompress } from "react-icons/fa";
import {
  RiFullscreenLine,
  RiVolumeUpLine,
  RiVolumeMuteLine,
} from "react-icons/ri";
import Slider from "rc-slider";
// import { Loading } from '../config/pages';
// import moment from 'moment';
import style from "../styles";
import "../pages/home.css";
import "../pages/login.css";
import ScrollableFeed from "react-scrollable-feed";
import axios from "axios";
// import {rgba} from 'polished';
import "rc-slider/assets/index.css";
import Message from "./messageComponent";
import LiveChat from "./liveChat";
import { Link } from "react-router-dom";
// import {analytics} from '../config/firebase';
// import ReactPixel from '../config/facebookpixel';

const SVolumeSlider = styled.div`
  ${style.mixins.flex({ align: "center", justify: "start", direction: "row" })}
  transition: ease 300ms;
  svg {
    padding-right: 0.75rem;
    color: white;
  }
  .rc-slider {
    width: 40%;
    opacity: 0;
  }
  &.--Open {
    width: 40%;
    .rc-slider {
      opacity: 1;
      width: 40%;
    }
  }
`;
const VolumeSlider = (props) => {
  const [isOpen, setIsOpen] = React.useState(true);
  let slider;
  const ref = (rSlider) => {
    slider = rSlider;
  };
  let MicIcon =
    props.volume !== 0 && props.muted === false
      ? RiVolumeUpLine
      : RiVolumeMuteLine;
  return (
    <React.Fragment>
      <SVolumeSlider className={`${isOpen ? "--Open" : ""}`}>
        <MicIcon
          onClick={() => {
            setIsOpen(!isOpen);
            props.mute();
          }}
        />
        {props.muted === false && (
          <Slider
            ref={ref}
            defaultValue={props.volume}
            min={0}
            max={100}
            onChange={() => {
              props.onChange && props.onChange(slider.state.value);
            }}
            onAfterChange={() => {
              props.onChange && props.onChange(slider.state.value);
            }}
          />
        )}
      </SVolumeSlider>
    </React.Fragment>
  );
};
// box-shadow: inset 0 0 7rem 8rem black;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgb(0 0 0 / 91%) 11%,
    rgba(45, 45, 45, 0) 11%,
    rgba(144, 144, 144, 0) 50%,
    rgba(17, 17, 17, 0) 91%,
    rgba(7, 7, 7, 1) 91%,
    rgba(0, 0, 0, 1) 100%
  );
  height: 100vh;
  clear: both;
`;
const SVideoControls = styled.div`
  svg {
    width: 4rem;
    height: 2rem;
    cursor: pointer;
    fill: ${style.constants.colors.white};
    &:hover {
      fill: ${style.constants.colors.primary};
    }
  }
`;
const VideoControls = (props) => {
  return (
    <SVideoControls>
      <VolumeSlider
        muted={props.muted}
        mute={props.onMute}
        volume={props.initialVolume * 100}
        onChange={(volume) => {
          props.onVolumeChange(volume);
        }}
      />
      <div className="middle mt-5">
        <div className="pb-4">
          <div className="w-20" style={{ cursor: "pointer" }}>
            <i
              className="fas fa-fast-backward"
              onClick={() => {
                props.onReverseClick();
              }}
            ></i>
          </div>
          <div className="w-20" style={{ cursor: "pointer" }}>
            <i
              className="fas fa-step-backward"
              onClick={() => {
                props.onReverseClick();
              }}
            ></i>
            <p
              style={{
                color: "white",
                fontSize: "12px",
                width: "180px",
                height: "16px",
                margin: "0 auto",
              }}
            >
              {props.previoustitle}
            </p>
          </div>
          <div className="w-20">
            {!props.playing && (
              <FaPlay
                onClick={() => {
                  props.onPlayClick();
                }}
              />
            )}
            {props.playing && (
              <FaPause
                onClick={() => {
                  props.onPauseClick();
                }}
              />
            )}
            <p
              style={{
                color: "white",
                fontSize: "12px",
                width: "180px",
                margin: "0 auto",
              }}
            >
              {props.currenttitle}
            </p>
          </div>
          <div className="w-20" style={{ cursor: "pointer" }}>
            <i
              className="fas fa-step-forward"
              onClick={() => {
                props.onSkipClick();
              }}
            ></i>
            <p
              style={{
                color: "white",
                fontSize: "12px",
                width: "180px",
                margin: "0 auto",
              }}
            >
              {props.nexttitle}
            </p>
          </div>
          <div className="w-20" style={{ cursor: "pointer" }}>
            <i
              className="fas fa-fast-forward"
              onClick={() => {
                props.onSkipClick();
              }}
            ></i>
          </div>
        </div>
        {screenfull.isFullscreen ? (
          <FaCompress
            style={{ position: "absolute", left: "5", top: "530" }}
            onClick={() => {
              props.onFullScreenClick();
            }}
          />
        ) : (
          <RiFullscreenLine
            style={{ position: "absolute", left: "5", top: "530" }}
            onClick={() => {
              props.onFullScreenClick();
            }}
          />
        )}
      </div>
    </SVideoControls>
  );
};

class NowUsVideoPlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      channel: null,
      videos: null,
      channelVideos: [],
      category: [],
      url: null,
      pip: false,
      playing: true,
      controls: false,
      light: false,
      volume: 0.8,
      muted: false,
      played: 0,
      loaded: 0,
      duration: 0,
      playbackRate: 1.0,
      loop: false,
      API_URL: "https://admin.nowus.com",
      nextVideo: null,
      previousVideo: null,
      showSpinner: false,
      auth_token: this.props.auth,
      video_id: null,
      video_likes: 0,
      video_dislikes: 0,
      comment: null,
      next_video_title: null,
      previous_video_title: null,
      current_video_title: null,
      videoCount: "",
      viewsCount: "",
      comments: null,
      displayCommentLike: false,
      currentPlaying: "",
      liveChat: null,
      allLiveChats: null,
      errAllLiveChats: null,
      totalMessage: null,
      channelName: null,
      trackLang: "",
    };
    this.pageRef = React.createRef();
  }

  handleEnded(props) {
    axios
      .get(
        this.state.API_URL +
          `/api/${this.state.channel}/${this.state.nextVideo}/play`
      )
      .then((res) => {
        this.setState({ videos: res.data.data.link });
        this.setState({ video_id: res.data.data.id });
        this.setState({ previousVideo: res.data.previous_video_id });
        this.setState({ nextVideo: res.data.next_video_id });
        this.setState({
          next_video_title: res.data.next_video_short_description,
        });
        this.setState({
          previous_video_title: res.data.previous_video_short_description,
        });
        this.setState({ current_video_title: res.data.data.short_description });
        this.addPoints();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChannelChange(channel, channelname) {
    axios
      .get(this.state.API_URL + `/api/${channel}/play`)
      .then((res) => {
        this.setState({ currentPlaying: channel });
        this.setState({ channelName: channelname });
        this.setState({ videos: res.data.data.link });
        this.setState({ video_id: res.data.data.id });
        this.setState({ channel: channel });
        this.setState({ next_video_title: res.data.next_video_title });
        this.setState({
          previous_video_title: res.data.previous_video_short_description,
        });
        this.setState({ current_video_title: res.data.data.short_description });
        this.setState({ showSpinner: false });
        this.setState({ displayCommentLike: true });
        this.setState({ nextVideo: res.data.next_video_id });
        this.setState({
          next_video_title: res.data.next_video_short_description,
        });
        this.setState({ current_video_title: res.data.data.short_description });
        this.setState({ previousVideo: res.data.previous_video_id });
        this.setState({ previous_video_title: res.data.previous_video_title });
        this.setState({ current_video_title: res.data.data.short_description });
        this.getLikes();
        this.getDislikes();
        this.showComment();
        this.getLiveChat();
        this.totalMessageCount();
      })
      .catch((err) => {
        console.log(err);
        this.setState({ video_likes: 0 });
        this.setState({ video_dislikes: 0 });
        this.setState({ showSpinner: true });
      });
  }

  handleFullscreen() {
    screenfull.toggle(this.pageRef.current);
  }
  handleVolumeChange(volume) {
    this.setState({ volume: Math.floor(volume) / 100 });
  }

  handleMute(muted) {
    this.setState({ muted: !this.state.muted });
  }

  homeVideo() {
    axios
      .get(this.state.API_URL + "/api/home-video")
      .then((res) => {
        this.setState({ videos: res.data.data.video });
        // this.setState({videos:'https://youtu.be/JRhZOLZx5JA'})
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getLikes() {
    axios
      .get(this.state.API_URL + `/api/comments/${this.state.video_id}/like`, {
        headers: { Authorization: `Bearer ${this.state.auth_token.token}` },
      })
      .then((res) => {
        if (res.data) {
          this.setState({ video_likes: res.data.data });
        } else {
          this.setState({ video_likes: 0 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getDislikes() {
    axios
      .get(
        this.state.API_URL + `/api/comments/${this.state.video_id}/dislike`,
        { headers: { Authorization: `Bearer ${this.state.auth_token.token}` } }
      )
      .then((res) => {
        if (res.data) {
          this.setState({ video_dislikes: res.data.data });
        } else {
          this.setState({ video_dislikes: 0 });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleLike(video_id) {
    axios
      .post(this.state.API_URL + `/api/comments/${video_id}/like`, null, {
        headers: {
          Authorization: `Bearer ${this.state.auth_token.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        this.getLikes();
        this.getDislikes();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  getCount() {
    axios
      .get(
        this.state.API_URL + "/api/video-count/" + this.state.auth_token.data.id
      )
      .then((res) => {
        this.setState({ videoCount: res.data.data.video_count });
      })
      .catch((err) => {
        console.log("hello");
        this.setState({ videoCount: 0 });
      });
  }

  handleDislike(video_id) {
    axios
      .post(
        this.state.API_URL + `/api/comments/${video_id}/dislike-video/dislike`,
        null,
        {
          headers: {
            Authorization: `Bearer ${this.state.auth_token.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.getLikes();
        this.getDislikes();
      })
      .catch((err) => {
        console.log(err.response);
      });
  }

  addPoints = async () => {
    try {
      let points = await axios.post(
        this.state.API_URL + "/api/user/add-points/" + this.state.auth_token.id
      );
      console.log(points);
    } catch (error) {
      console.log("points not added");
    }
  };

  handleCommentChange = (e) => {
    console.log(e.target.value);
    this.setState({ comment: e.target.value });
  };

  handleCommentSubmit = (e) => {
    e.preventDefault();
    axios
      .post(
        this.state.API_URL + `/api/comments/${this.state.video_id}`,
        { comments: this.state.comment },
        {
          headers: {
            Authorization: `Bearer ${this.state.auth_token.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({ comment: "" });
        this.showComment();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePlay = () => {
    axios
      .post(
        this.state.API_URL + `/api/video-count/` + this.state.auth_token.id,
        null,
        {
          headers: {
            Authorization: `Bearer ${this.state.auth_token.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        this.setState({ videoCount: res.data.data.video_count });
      })
      .catch((err) => {
        this.setState({ videoCount: 0 });
      });
  };

  handleViews = async () => {
    let channelid = {
      channel: this.state.channel,
    };
    let views = await axios.post(
      this.state.API_URL + `/api/views-count/` + this.state.video_id,
      channelid
    );
    console.log(views);
  };

  showComment = () => {
    axios
      .get(this.state.API_URL + "/api/comments/" + this.state.video_id, {
        headers: {
          Authorization: `Bearer ${this.state.auth_token.token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        this.setState({ comments: res.data.data });
      })
      .catch((err) => {
        console.log("hello");
      });
  };

  handleLiveChatChange = (e) => {
    console.log(e.target.value);
    this.setState({ liveChat: e.target.value });
  };

  handleLiveChat = (event) => {
    event.preventDefault();
    console.log(this.state.video_id);
    axios
      .post(
        this.state.API_URL + `/api/live-chat/${this.state.channel}/store`,
        { message: this.state.liveChat },
        {
          headers: {
            Authorization: `Bearer ${this.state.auth_token.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({ liveChat: "" });
        this.getLiveChat();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getLiveChat = () => {
    axios
      .get(
        this.state.API_URL + "/api/video/" + this.state.channel + "/live-chat",
        {
          headers: {
            Authorization: `Bearer ${this.state.auth_token.token}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
        this.setState({ allLiveChats: res.data.data });
      })
      .catch((err) => {
        console.log(err);
        this.setState({ allLiveChats: null });
        this.setState({ errAllLiveChats: err.response.data.msg });
      });
  };

  totalMessageCount = async () => {
    let total_message = await axios.get(
      "https://admin.nowus.com/api/total-message/" + this.state.auth_token.id
    );
    this.setState({ totalMessage: total_message.data.data });
  };

  componentDidMount() {
    axios
      .get(this.state.API_URL + "/api/category")
      .then((res) => {
        console.log(res);
        this.setState({ category: res.data.Category });
        this.homeVideo();
        this.getLikes();
        this.getDislikes();
        this.getCount();
      })
      .catch((err) => {
        this.setState({ showSpinner: true });
      });
  }

  render() {
    const {
      videos,
      playing,
      controls,
      light,
      volume,
      muted,
      loop,
      playbackRate,
      pip,
      API_URL,
      channel,
      nextVideo,
      previousVideo,
      video_likes,
      video_id,
      video_dislikes,
      next_video_title,
      previous_video_title,
      current_video_title,
      videoCount,
      allLiveChats,
      comments,
      displayCommentLike,
      currentPlaying,
      errAllLiveChats,
      totalMessage,
      channelName,
    } = this.state;
    const youtubePlayer = this.player
      ? this.player.getInternalPlayer()
      : undefined;
    return (
      <div>
        <div
          className="nowusVideoPlayer content"
          style={{ overflow: "hidden" }}
        >
          <div className="container hight-auto">
            <div className="menu">
              <nav
                className="navbar navbar-expand-md"
                style={{ zIndex: "9999" }}
              >
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsibleNavbar"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  style={{ display: "inherit" }}
                  id="collapsibleNavbar"
                >
                  <ul className="navbar-nav" style={{ display: "inline" }}>
                    {/* <li className="nav-item" style={{display:'inherit', float:'left'}}>
                          <Link className="nav-link text-white" to="/home">Home</Link>
                        </li> */}

                    {/* style={{display:'inherit', float:'left',color:'white'}} */}
                    {this.state.category.map((category) => {
                      return (
                        <li
                          className={
                            currentPlaying === category.id
                              ? "nav-item watch-menu"
                              : "nav-item watch-nav-menu"
                          }
                          key={category.id}
                          style={{ display: "inherit", float: "left" }}
                        >
                          <p
                            className="nav-link"
                            style={{
                              cursor: "pointer",
                              fontSize: "20px",
                              whiteSpace: "nowrap",
                              padding: "0 10px",
                              fontWeight: "bold",
                            }}
                            onClick={() =>
                              this.handleChannelChange(
                                category.id,
                                category.name
                              )
                            }
                          >
                            {category.name}
                          </p>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="watch">
          <div className="container-fluid">
            <div className="row mt-5 m-0">
              <div className="col-10" ref={this.pageRef}>
                <VideoOverlay />
                <ReactPlayer
                  width="100%"
                  height="100vh"
                  ref={this.ref}
                  config={{
                    youtube: {
                      embedOptions: { showinfo: 0 },
                    },

                    file: {
                      attributes: {
                        crossOrigin: "anonymous",
                      },
                      tracks: [
                        {
                          kind: "subtitles",
                          src: "https://raw.githubusercontent.com/benwfreed/test-subtitles/master/mmvo72166981784.vtt",
                          srcLang: "en",
                          default: true,
                        },
                      ],
                    },
                  }}
                  style={{
                    zIndex: "0",
                    backgroundColor: "#000",
                    width: "100%",
                  }}
                  url={videos}
                  pip={pip}
                  playing={playing}
                  controls={controls}
                  light={light}
                  loop={loop}
                  playbackRate={playbackRate}
                  volume={volume}
                  muted={muted}
                  onReady={() => {}}
                  onStart={this.handleViews}
                  onPlay={this.handlePlay}
                  onEnablePIP={this.handleEnablePIP}
                  onDisablePIP={this.handleDisablePIP}
                  onPause={this.handlePause}
                  onBuffer={() => this.setState({ showSpinner: true })}
                  onSeek={(e) => console.log("onSeek", e)}
                  onEnded={() => {
                    this.handleEnded();
                  }}
                  onError={(e) => console.log("onError", e)}
                  onProgress={this.handleProgress}
                  onDuration={this.handleDuration}
                />
                <VideoControls
                  style={{ position: "relative" }}
                  nexttitle={next_video_title}
                  previoustitle={previous_video_title}
                  currenttitle={current_video_title}
                  playing={this.state.playing}
                  initialVolume={this.state.volume * 100}
                  onVolumeChange={this.handleVolumeChange.bind(this)}
                  onReverseClick={() => {
                    axios
                      .get(API_URL + `/api/${channel}/${previousVideo}/play`)
                      .then((res) => {
                        this.setState({ videos: res.data.data.link });
                        this.setState({ video_id: res.data.data.id });
                        this.setState({
                          next_video_title:
                            res.data.next_video_short_description,
                        });
                        this.setState({ nextVideo: res.data.next_video_id });
                        this.setState({
                          previousVideo: res.data.previous_video_id,
                        });
                        this.setState({
                          current_video_title: res.data.data.short_description,
                        });
                        this.setState({
                          previous_video_title:
                            res.data.previous_video_short_description,
                        });
                        this.getLikes();
                        this.getDislikes();
                        this.showComment();
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                  }}
                  onSkipClick={() => {
                    axios
                      .get(API_URL + `/api/${channel}/${nextVideo}/play`)
                      .then((res) => {
                        this.setState({ videos: res.data.data.link });
                        this.setState({ video_id: res.data.data.id });
                        this.setState({
                          previousVideo: res.data.previous_video_id,
                        });
                        this.setState({ showSpinner: false });
                        this.setState({
                          next_video_title:
                            res.data.next_video_short_description,
                        });
                        this.setState({
                          previous_video_title:
                            res.data.previous_video_short_description,
                        });
                        this.setState({ nextVideo: res.data.next_video_id });
                        this.setState({
                          current_video_title: res.data.data.short_description,
                        });
                        this.getLikes();
                        this.getDislikes();
                        this.showComment();
                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({ showSpinner: true });
                      });
                  }}
                  onPauseClick={() => {
                    this.setState({ playing: false });
                    youtubePlayer && youtubePlayer.pauseVideo();
                  }}
                  onPlayClick={() => {
                    this.setState({ playing: true });
                    youtubePlayer && youtubePlayer.playVideo();
                  }}
                  onFullScreenClick={() => {
                    this.handleFullscreen();
                  }}
                  muted={muted}
                  onMute={this.handleMute.bind(this)}
                />
              </div>
              {displayCommentLike === true ? (
                <div
                  className="col-2"
                  style={{ cursor: "pointer", color: "white" }}
                >
                  <h6 style={{ color: "white", textAlign: "center" }}>
                    Live&nbsp;Chat in {channelName} channel room
                  </h6>
                  <LiveChat channel={this.state.channel} />

                  <Message total={totalMessage}></Message>
                  <p className="text-success thumb">
                    <i
                      onClick={() => this.handleLike(video_id)}
                      className="fas fa-thumbs-up"
                    ></i>{" "}
                    <span>({video_likes})Likes</span>
                  </p>

                  <p className="text-danger thumb2">
                    <i
                      onClick={() => this.handleDislike(video_id)}
                      className="fas fa-thumbs-down"
                    ></i>{" "}
                    <span>({video_dislikes})Dislikes</span>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NowUsVideoPlayer;
