import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import "../pages/home.css";
import "../pages/login.css";
import Cs from "../components/colorSelect";
import ScrollableFeed from "react-scrollable-feed";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
const Message = ({ total }) => {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [friends, setFriends] = useState(null);
  const [friendErr, setFriendErr] = useState(null);
  const [allMessage, setAllMessage] = useState(true);
  const [messageBox, setMessageBox] = useState(false);
  const [username, setUsername] = useState(null);
  const [message, setMessage] = useState("");
  const [senderId, setSenderId] = useState(null);
  const [userMessage, setUserMessage] = useState([]);
  const [flashUserMsg, setFlashUserMsg] = useState("");
  const [isRecentMessage, setIsRecentMessage] = useState(false);

  const auth = useSelector((state) => state.currentUser);
  const handleSearch = async (e) => {
    setEmail(e.target.value);
  };

  const showModal = () => {
    setShow(true);
    axios
      .get("https://admin.nowus.com/api/show-private-message/" + auth.user.id)
      .then((res) => {
        console.log(res.data.data);
        setUserMessage(res.data.data);
      })
      .catch((err) => {
        setFlashUserMsg(err.response.data.message);
        console.log(err.response);
      });
  };

  const closeMessageBox = () => {
    setAllMessage(true);

    setMessageBox(false);
  };

  //send input message
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };

  useEffect(() => {
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "anyKey",
      cluster: "mt1",
      encrypted: true,
      wsHost: "admin.nowus.com",
      wsPort: 6002,
      wssPort: 6002,
      forceTLS: false,
      disableStats: true,
      authEndpoint: "/broadcasting/auth",
      enabledTransports: ["ws", "wss"],
    });

    window.Echo.channel("privatechat").listen("Message", (event) => {
      console.log(event);
      setUserMessage((oldMessages) => [...oldMessages, event]);
    });
  }, []);

  const handleSearchSubmit = async (e) => {
    try {
      let findUser = await axios.get(
        `https://admin.nowus.com/api/friends?email=${email}`
      );
      setEmail("");
      console.log(findUser.data[0]);
      setFriends(findUser.data[0]);
      setSenderId(findUser.data[0].id);
      setUsername(auth.user.firstName);
    } catch (error) {
      console.log(error.response.data.msg);
      setFriends(null);
      setFriendErr(error.response.data.msg);
    }
  };

  //input submit
  const submitMessage = async (e) => {
    e.preventDefault();
    let data = {
      message: message,
      sender_id: auth.user.id,
      reciver_id: senderId,
    };
    console.log(data);
    try {
      let myMessage = await axios.post(
        "https://admin.nowus.com/api/chat",
        data
      );
      // userMessage.push(data);
      // setUserMessage({ ...data });
      console.log(myMessage);
      setIsRecentMessage(true);
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  //Open Message
  const handleInput = () => {
    if (messageBox === false) {
      setMessageBox(true);
    } else {
      setMessageBox(false);
    }
  };
  return (
    <div style={{ marginTop: "50px" }}>
      <button onClick={showModal} style={{ background: "#000" }}>
        <span style={{ color: "white", position: "relative" }}>
          <i
            style={{
              fontSize: "20px",
              color: "#fff",
              position: "absolute",
              left: "62px",
              top: "-12px",
            }}
            class="fas fa-comment-alt"
          ></i>
          <strong>INBOX</strong>
        </span>
      </button>
      {show === true ? (
        <div className="modal display-block">
          <section className="modal-main">
            <div className="row">
              <div className="col-md-6 search">
                <h5 className="text-center">Find Friends</h5>
                <div class="search-container">
                  <div className="row">
                    <div className="col-md-10">
                      <input
                        type="text"
                        value={email}
                        placeholder="Email..."
                        onChange={handleSearch}
                      />
                    </div>
                    <div className="col-md-2">
                      <button
                        style={{ marginTop: "4px" }}
                        className="btn btn-primary"
                        onClick={handleSearchSubmit}
                      >
                        Find
                      </button>
                    </div>
                  </div>
                </div>
                {friends !== null ? (
                  <div className="message-chat">
                    <div className="message-container">
                      <div className="message">
                        <h5 className="">
                          Name :- {friends.firstName} {friends.lastName}
                        </h5>
                        <p>Email :- {friends.email}</p>
                        <button
                          className="btn btn-primary"
                          onClick={handleInput}
                        >
                          Send a message
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center" style={{ color: "red" }}>
                    <p>{friendErr}</p>
                  </div>
                )}
              </div>

              <div className="col-md-6">
                <h5 className="text-center">Message Box</h5>
                <div className="">
                  <div className="">
                    {messageBox === true ? (
                      <div>
                        <div
                          className="friend-message"
                          style={{ height: "52vh" }}
                        >
                          <div>
                            <ul id="chat">
                              <ScrollableFeed>
                                {userMessage.length > 0
                                  ? userMessage.map((msg) => {
                                      return (
                                        <>
                                          {msg.sender_id !== auth.user.id ? (
                                            <li class="you">
                                              <div class="entete">
                                                <span class="status green"></span>
                                                <h2>Vincent</h2>
                                                <h3>10:12AM, Today</h3>
                                              </div>
                                              <div class="private_message">
                                                {msg.message}
                                              </div>
                                            </li>
                                          ) : (
                                            <li class="me">
                                              <div class="entete">
                                                <h3>10:12AM, Today</h3>
                                                <h2>Vincent</h2>
                                                <span class="status blue"></span>
                                              </div>
                                              <div class="private_message">
                                                {msg.message}
                                              </div>
                                            </li>
                                          )}
                                        </>
                                      );
                                    })
                                  : ""}
                              </ScrollableFeed>
                            </ul>
                          </div>
                        </div>

                        <div className="personal-message-form">
                          <form onSubmit={(e) => submitMessage(e)}>
                            <input
                              type="text"
                              value={message}
                              onChange={handleMessage}
                              placeholder="Type here"
                            />
                          </form>
                          <button
                            className="btn btn-danger"
                            onClick={closeMessageBox}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
            <button
              className="modal-close-button"
              type="button"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </section>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Message;
