export  const  REGISTER_USER_SUCCESS='REGISTER_USER_SUCCESS';
export const   REGISTER_ERROR='REGISTER_ERROR';
export const   LOGIN_SUCCESS='LOGIN_SUCCESS';
export const   LOGIN_ERROR='LOGIN_ERROR';
export const   LOGOUT_SUCCESS='LOGOUT_SUCCESS';
export const   UPDATE_USER_SUCCESS='UPDATE_USER_SUCCESS';
export const   UPDATE_USER_ERROR='UPDATE_USER_ERROR';
export const   UPDATE_USER_BIRTH='UPDATE_USER_BIRTH';
export const   USER_DELETE_SUCCESS='USER_DELETE_SUCCESS';
export const   USER_DELETE_ERROR = 'USER_DELETE_ERROR';


// export default UserActionTypes;
