import {REGISTER_USER_SUCCESS,REGISTER_ERROR,LOGIN_SUCCESS,LOGIN_ERROR,LOGOUT_SUCCESS,UPDATE_USER_SUCCESS,UPDATE_USER_ERROR,UPDATE_USER_BIRTH,USER_DELETE_SUCCESS,USER_DELETE_ERROR} from './user.types';

import AuthService from '../../services/auth.service';



export const register = (firstName , lastName , email , address , city , state , zipcode , birth , password,c_password) => (dispatch) =>{
    return AuthService.userRegister(firstName , lastName , email , address , city , state , zipcode , birth , password,c_password).then(
        (response) =>{
            dispatch({
                type:REGISTER_USER_SUCCESS,
                payload : response.data
            })
            return Promise.resolve();
        },
        (error) => {
            dispatch({
                type:REGISTER_ERROR,
                payload:error.response.data
            })
            return Promise.reject();
        }
    )
}


export const login = (email , password) => (dispatch) =>{
    return AuthService.userLogin(email,password).then(
        (response)=>{
            dispatch({
                type:LOGIN_SUCCESS,
                payload: response.data
            });
        },
        (error) =>{
            dispatch({
                type:LOGIN_ERROR,
                payload:error.response.data
            });
            
        }
    )
}


export const cancelAccount = (user_id) => (dispatch) =>{
    return AuthService.deleteAccount(user_id).then(
        (response)=>{
            dispatch({
                type:USER_DELETE_SUCCESS,
                payload: response
            });
        },
        (error) =>{
            dispatch({
                type:USER_DELETE_ERROR,
                payload:error.response
            });
            
        }
    )
}


export const logout = ()=> (dispatch)=>{
    AuthService.userLogout();
    dispatch({
        type:LOGOUT_SUCCESS
    })
}



export const updateUser = (data) => (dispatch) =>{
    console.log(data);
    return AuthService.updateUser(data).then(
        (response)=>{
            dispatch({
                type:UPDATE_USER_SUCCESS,
                payload: response
            });
        },
        (error) =>{
            dispatch({
                type:UPDATE_USER_ERROR,
                payload:error
            });

            
        }
    )
}

export const updateBirth = (data) => (dispatch) =>{
    console.log(data);
    return AuthService.updateBirth(data).then(
        (response)=>{
            dispatch({
                type:UPDATE_USER_BIRTH,
                payload: response
            });
        },
    )
}

