import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import GlobalStyle from './styles/global';
import {FontFace} from './styles/constants/fonts';
import 'normalize.css';

import {Provider,useSelector} from 'react-redux';
import store from './redux/store';





function AwaitAuth({children}) {
  const auth=useSelector(state => state.currentUser);
  if(auth.isloggedIn === false){
    return children;
  }
  // if(auth.isLoggedIn === false) return (<List flex={{align:'center', justify:'center'}} style={{width:'100%',height:'100%'}}>
  //   <h5>Loading<sup style={{fill:'black'}}><FaPlayCircle/></sup></h5>
  // </List>);
  return children;
}


ReactDOM.render(
  <React.Fragment>
    <FontFace />
    <GlobalStyle />
      <Provider store={store}>
          <AwaitAuth>
            <App />
          </AwaitAuth>
      </Provider>
  </React.Fragment>
  ,document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
