import React from 'react';
import style from '../../styles';
import styled from 'styled-components';

const SdividerF=styled.section`
height:${props => props.height||'5rem'};
width:${props => props.width||'100%'};
background-image:url(${props => props.img});
background-color:${props => props.color};
${style.mixins.background()}
object-fit: cover;
display: flex;
justify-content:center;
align-items:center;
top:0;
left:0;
right:0;
bottom:0;
`;

const DividerF=(props) => {
  return (
    <SdividerF {...props}>
      {props.children}
    </SdividerF>
  );
};

export default DividerF;