import React from 'react';
import styled from 'styled-components';
import style from '../styles';
import BgImg from './bgImg';
import {object} from 'to-style';
import gsap from 'gsap';

const SPageSection=styled.section`
  ${props => props.flex? style.mixins.flex({align: props.flex.align||'center',justify: props.flex.justify||'center',direction: props.flex.direction||'column'}):''}
  ${props => props.size? `height:${props.size};`:'height:calc(100vh);'}
  ${props => props.bgColor? `background-color:${props.bgColor};`:''}
  box-sizing:border-box;
  width:100%;
  overflow:hidden;
  position: relative;
  transition:ease 1s;
  min-height:fit-content;
  height: 100vh;
  overflow: scroll;
  box-sizing:border-box;
  ${props => style.mixins.media(style.mixins.mediaSmall,`
    margin:0;
    border-radius:0px;
    ${props.size? `height:${props.size};`:'height:calc(100vh);'}
  `)}
`;

const PageSection=(props) => {
  React.useEffect(() => {
    props.bgImg&&gsap.to(`${SPageSection} #${props.id+'Bg'}`,{boxShadow: `inset 0 0 100px 10px ${style.constants.colors.black}`,...object(style.mixins.zoomIn(false,1.03)),stagger: 1});
  });
  return (
    <SPageSection
      {...props}
      style={{
        ...props.style,
      }}
    >
      <BgImg id={props.id+'Bg'} img={props.bgImg} />
      {props.children}
    </SPageSection>
  );
};

export default PageSection;
