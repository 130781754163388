import React from "react";

//Page Things
import Page from "./page";
import Button from "../components/button";
import Cs from '../components/colorSelect';
import { useParams,useHistory } from 'react-router-dom'


//Redux
import {connect} from 'react-redux';
import { useDispatch } from "react-redux";

//Images
import  "./login.css";
import axios from "axios";

const mapStateToProps=state => {
  return {
    id: state.currentUser.user,
    error:state.currentUser
  };
};


const ForgotPassword =connect(mapStateToProps)(props => {
  const [stateError,setStateError]=React.useState(null);
  const dispatch = useDispatch();
  let history = useHistory();
  const {id} = useParams();
  const emailRef=React.createRef();
  const passwordRef=React.createRef();
  const confirmPasswordRef=React.createRef();
  const handleSubmit=()=>{
    let token = id;
    let email = emailRef.current.value;
    let password = passwordRef.current.value;
    let password_confirmation = confirmPasswordRef.current.value;
    
    if(password !== password_confirmation) {return setStateError({message: 'Confirm Password Do Not Match'});}

    axios.post("https://admin.nowus.com/api/password/reset",{
      token,
      email,
      password,
      password_confirmation
  }).then(()=>{
      localStorage.removeItem('user');
      history.push('/login');
  }).catch(err=>{
    setStateError(err.response.data.error)
  })
  }

  return (
    <React.Fragment>
      <div className="container1 hight-auto">
          <div className="row m-0">
            <div className="col-md-3  py-5">
                
            </div>
            <div className="col-md-6 py-5">
              <div className="card mx-5 ">
                <div className="card-body my-5">
                  <h4 className="text-center">Reset Password</h4>
                  <div className="form-group">
                      <input type="email" className="form-control" ref={emailRef} name='email' placeholder="Email"/>
                  </div>
                  <div className="form-group">
                      <input type="password" className="form-control" ref={passwordRef} name='password' placeholder="Password"/>
                  </div>
                  <div className="form-group">
                      <input type="password" className="form-control" ref={confirmPasswordRef} name='confirmPassword' placeholder="Confirm Password"/>
                  </div>
                  <div className="row">
                    <div className="form-group col-6">
                        {stateError&&(<h6><Cs color='red'>{stateError.message}</Cs></h6>)}
                        <Button onClick={handleSubmit}><p>Update</p></Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-3  py-5">
                
            </div>
          </div>
      </div>
    </React.Fragment>
  );
});


export default ForgotPassword;
