import React, { useEffect, useState } from "react";
import style from "../styles";

//Pages
import Page from "./page";
import PageSection from "../components/pageSection";
// import Header from '../components/header';
// import {defaultHeader} from '../config/pages';

// import Intro from '../components/intro';
import AccordianCollapse from "../components/accordianCollapse";
import IntroF from "../components/fullscreen/introF";
import DividerF from "../components/fullscreen/dividerF";

//Images
// import introImg from '../resources/photo-1477281765962-ef34e8bb0967.jpg';
import FAQBackground from "../resources/FAQBackground.svg";
import DividerBackground from "../resources/dividerbackground.png";
import SeniorWomanCallImg from "../resources/seniorwomancallcenter.jpg";
import { Link } from "react-router-dom";
import axios from "axios";

const Faq = (props) => {
  const [faqList, setFaqList] = useState([]);
  useEffect(() => {
    axios.get("https://admin.nowus.com/api/faq-data").then((res) => {
      setFaqList(res.data.Faq);
    });
  }, []);

  return (
    <Page defaultHeader defaultFooter title="NowUs - FAQ">
      <IntroF
        img={SeniorWomanCallImg}
        style={{ paddingTop: "100px", height: "48rem" }}
        bgColor={style.constants.colors.primary}
        sidebarColor={style.constants.colors.secondary}
      >
        <h1
          style={{ color: "transparent", "-webkit-text-stroke": "5px black" }}
        >
          {" "}
          FAQ
        </h1>
        <p style={{ fontStyle: "italics" }}>
          We are always happy to help! <br /> Scroll Down!
        </p>
      </IntroF>
      <DividerF
        height="3rem"
        img={DividerBackground}
        color={style.constants.colors.white}
      />

      <PageSection
        style={{
          backgroundImage: `url(${FAQBackground})`,
          height: "fit-content",
          padding: "2.5rem",
        }}
      >
        {faqList.map((list, index) => (
          <AccordianCollapse
            key={index}
            title={list.title}
            content={list.description}
          />
        ))}

        {/* <AccordianCollapse
          title="What are the Various Membership Plans?"
          content={(function () {
            return (
              <div>
                <div>NowUs has ONE membership and it’s FREE.</div>

                <div>
                  We have a super basic membership signup to ensure a more
                  personalized NowUs experience for you (no cookies or sneaky
                  data mining like other services).
                </div>
              </div>
            );
          })()}
        />

        <AccordianCollapse
          title="What TV Shows & Movies Does NowUs Offer?"
          content="NowUs is a streaming service that allows our subscribing members to watch a wide variety of award-winning shows, movies, documentaries and more on our 25+ channels, organized by subject for easy viewing... AND as we continue to grow, we will be adding even more channels and shows and movies for everyone 50plus… check it out FREE."
        />
        <AccordianCollapse
          title="What Streaming Services does NowUs Work On?"
          content="On thousands of internet-connected devices. With NowUs, you can enjoy unlimited viewing of our content. There's always something new to discover, and more TV shows and movies are added every week! That’s right every week!"
        />
        <AccordianCollapse
          title="How do I Get Started watching NowUs?"
          content={(function () {
            return (
              <div>
                <div>How do I Get Started Watching NowUs?</div>
                <div style={{ marginTop: 20 }}>Step 1: Sign up for free.</div>
                <div style={{ marginTop: 20 }}>Step 2: Click on WATCH.</div>
                <div style={{ marginTop: 20 }}>
                  Step 3: Start watching endless amounts of TV shows and videos
                  selected for everyone 50plus.
                </div>
              </div>
            );
          })()}
        />
        <AccordianCollapse
          title="How do I Sign In?"
          content="Once you've opened the NowUs app or NowUs website, 
          select LOGIN to access your account 
          and Login
          and then click “WATCH”
          and then begin watching shows and movies FREE.
          "
        />
        <AccordianCollapse
          title="What is in my Profile?"
          content="You created your profile when you first signed up and helps NowUs provide you a more personalized NowUs experience"
        />
        <AccordianCollapse
          title="How do I Watch Shows?"
          content="To find your next binge, go to the WATCH page of NowUs and click on the channel that feels what you're in the mood to watch. Once you start viewing your first video on any of our channels, the NowUs player will continue to play videos on that channel until you pause, change channels or sign out of your NowUs account."
        />
        <AccordianCollapse
          title="How do I Manage my Account?"
          content={(function () {
            return (
              <div>
                <div>Anytime you want to cancel your account,</div>
                <div style={{ marginTop: 20 }}>
                  1. simply go to the <Link to="/"> www.NowUs.com</Link>
                </div>
                <div style={{ marginTop: 20 }}>
                  2. Then click on your <strong>Account</strong>
                </div>
                <div style={{ marginTop: 20 }}>
                  3. Then click on the <strong>Cancel Account</strong> button.
                </div>
                <div style={{ marginTop: 20 }}>
                  4. Easy peasy! And we are very sorry to see you cancel.
                </div>
              </div>
            );
          })()}
        />
        <AccordianCollapse
          title="How do I Contact Customer Service?"
          content="Contacting NowUs is now easier than ever when you contact NowUs; email your question or interest to: CustomerService@NowUs.com"
        /> */}
      </PageSection>
    </Page>
  );
};

export default Faq;
