import React from 'react';
import style from '../styles';
import styled from 'styled-components';
import {SCard} from './card';

const SCardGrid=styled.div `
  ${props=>style.mixins.grid({rows:props.rows,columns:props.columns,gap:props.gap})}
  ${props=>props.textAlign&&`text-align:${props.textAlign};`}
  ${SCard}{
    ${props=>style.mixins.gridItem({rowSpan:props.rowSpan,columnSpan:props.columnSpan,justify:props.cardJustify,align:props.cardAlign})}
  }
  ${style.mixins.media(style.mixins.mediaSmall,`
    ${style.mixins.grid({columns:1,})}
  `)}
`

const CardGrid = props => {
  return (
    <SCardGrid {...props}>
      {props.children}
    </SCardGrid>
  )
}

export default CardGrid
