import React, { useState } from "react";

//Page Things
import Page from "./page";
// import { Redirect, Link } from "react-router-dom";
// import Button from "../components/button";
import Cs from '../components/colorSelect';
import './home.css';



//Images
import  "./login.css";
import axios from "axios";



const Advertise =props => {
    const [success,setSuccess]=useState(null);
    const [stateError,setStateError]=useState(null);
    const nameRef = React.createRef();
    const emailRef = React.createRef();
    const phoneRef = React.createRef();
    const companyRef = React.createRef();
    const designationRef = React.createRef();
    const messageRef = React.createRef();

    const handleSubmit=()=>{
        const name=nameRef.current.value;
        if(!name){return setStateError({message:"Missing Name"})}
        const email=emailRef.current.value;
        if(!email){return setStateError({message:"Missing Email"})}
        const phone = phoneRef.current.value;
        if(!phone){return setStateError({message:"Missing Phone Number"})}
        const company = companyRef.current.value;
        if(!company){return setStateError({message:"Missing Company Name"})}
        const designation = designationRef.current.value;
        if(!designation){return setStateError({message:"Missing Designation"})}
        const message = messageRef.current.value;

        axios.post('https://admin.nowus.com/api/advertise',{name,email,phone,company,designation,message}).then(res=>{
            nameRef.current.value ='';
            emailRef.current.value='';
            phoneRef.current.value='';
            companyRef.current.value='';
            designationRef.current.value=''
            messageRef.current.value='';
            setSuccess(res.data.msg);
        }).catch(err=>{
            setStateError(err.data.msg);
        })
      };
  return (
    <React.Fragment>
    <Page defaultFooter defaultHeader={{ relative: true }} Title="Login">
        <div class="form-section py-5">
            <div class="container">
                <div class="row">
                    <div class="col-md-7">
                        <h1 style={{fontSize:'40px'}}>Advertise on NowUs</h1>
                        <h5>Send us your details:</h5>

                        <div class="form-section2">
                            <div class="form-group">
                                <input type="text" class="form-control" ref={nameRef} name="name" placeholder="Name"/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" ref={emailRef} name="email" placeholder="Email" required/>
                            </div>
                            <div class="form-group">
                                <input type="number" class="form-control" ref={phoneRef} name="phone" placeholder="Phone" required/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" ref={companyRef} name="company" placeholder="Company" required/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" ref={designationRef} name="designation" placeholder="Designation" required/>
                            </div>
                            <div class="form-group">
                                <input type="text" class="form-control" ref={messageRef} name="message" placeholder="Message"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <div class="form-section3">
                            <p>Please send your details to get advertised on NowUS Website.</p>
                        </div>
                    </div>
                    <div class="col-12">
                        {success&&(<h6><Cs color='green'>{success}</Cs></h6>)}
                        {stateError&&(<h6><Cs color='red'>{success ? '' : stateError.message}</Cs></h6>)}
                        <button class="btn btn-danger" onClick={handleSubmit}>Submit</button>
                    </div>
                </div>

            </div>

        </div>
    </Page>
    </React.Fragment>
  );
};


export default Advertise;
