import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import style from "../styles";
import gsap from "gsap";

// import DividerBackground from "../resources/dividerbackground.png";

export const SFloatingFooter = styled.footer`
  position: absolute;
  margin: 0 1.875rem 1.875rem 0;
  li {
    padding: 0 1.875rem;
    opacity: 0;
    transform: translateY(-30px);
    svg {
      width: 1.5rem;
      height: 1.5rem;
      transition: 0.5s ease;
      :hover {
        ${style.mixins.zoomIn()}
        padding:0 0.2rem;
      }
    }
  }
  bottom: 0;
  right: 0;
  ${style.mixins.flex({ align: "center", justify: "flex-end" })}
  ${style.mixins.media(
    style.mixins.mediaSmall,
    `
    left:0;
    margin:0;
    margin-bottom:1.875rem;
    ${style.mixins.flex({ justify: "center" })}
  `
  )}
`;

export const FloatingFooter = (props) => {
  React.useEffect(() => {
    gsap.to(`${SFloatingFooter} li`, {
      y: 0,
      opacity: 1,
      stagger: 0.2,
      duration: 1,
      delay: 0.1,
    });
  });
  return (
    <SFloatingFooter>
      {props.social &&
        props.social.map((item) => {
          return (
            <li>
              <a href={item.href} target="_blank" rel="noopener noreferrer">
                <item.icon />
              </a>
            </li>
          );
        })}
    </SFloatingFooter>
  );
};

export const SFooter = styled.footer`
  padding: 1rem 0;
  position: relative;
  background-color: ${style.constants.colors.white};
  color: ${style.constants.colors.black};
  span {
    width: 100%;
    text-align: center;
  }
  section {
    ${style.mixins.flex({ align: "center", justify: "space-around" })}
  }
  h6 {
    margin: 0;
  }
`;

export const Footer = (props) => {
  return (
    <SFooter>
      <section>
        {props.lists.map((list) => {
          return (
            <ul>
              {list.items &&
                list.items.map((item) => {
                  return (
                    <Link to={item.href}>
                      <h6>{item.title}</h6>
                    </Link>
                  );
                })}
            </ul>
          );
        })}
      </section>
      <h6
        style={{
          paddingTop: "0.5rem",
          textAlign: "center",
          fontSize: "10px",
          textTransform: "none",
        }}
      >
        {props.bottomText}
      </h6>
    </SFooter>
  );
};

export default Footer;
