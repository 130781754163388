import React from 'react';
import style from '../styles';
import styled from 'styled-components';

export const SListItem=styled.li `
  ${props=>{return props.flexItem&&style.mixins.flexItem({...props.flexItem})}}
`

export const ListItem=props=>{
  return (
    <SListItem {...props} style={{...props.style}}>
      {props.children}
    </SListItem>
  )
}

export const SList=styled.ul `
  ${props=>{return props.flex&&style.mixins.flex({...props.flex})}}
  overflow:auto;
`

export const List = props => {
  return (
    <SList {...props}>
      {props.children}
    </SList>
  )
}

export default List
