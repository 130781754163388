import React from 'react';
import styled from 'styled-components';
import {rgba} from 'polished';
import style from '../styles';

const SDiv=styled.div `
  margin:0.5rem 0 0 0;
  width:100%;
  input{
    width:100%;
    box-sizing:border-box;
    :hover{
      ::placeholder{
        color:${rgba(style.constants.colors.black,0.8)};
      }
    }
    ::placeholder{
      color:${rgba(style.constants.colors.black,0.7)};
      font-size:0.8rem;
      transition:ease 500ms;
      font-weight:bolder;
      font-family:${style.constants.fonts.regular};
    }
  }
  h6{
    margin-block-end:0.3rem;
  }
`
export const NamedInput=React.forwardRef((props,ref)=>{
  return (
    <SDiv style={{...props.style}}>
      {props.name&&(<h6>{props.name}{(props.r||props.required)&&'*'}</h6>)}
      {
        props.name === 'Gender' ? <select ref={ref} type={props.name?props.type||props.name.replace(/\s/,'').toLowerCase():''} required={props.required}>
          <option value="" disabled selected hidden>Choose Gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          <option value="others">Others</option>
        </select> : <input onChange={props.onChange} minLength={props.minLength} maxLength={props.maxLength} required={props.required} pattern={props.pattern} id={props.id} ref={ref} type={props.name?props.type||props.name.replace(/\s/,'').toLowerCase():''} defaultValue={props.defaultValue} placeholder={props.placeholder}/>
      }
      
    </SDiv>
  );
});

export const NamedSelect=React.forwardRef((props,ref)=>{
  console.log(props);
  return (
    <SDiv style={{...props.style}}>
      {props.name&&<h6>{props.name}{(props.r||props.required)&&'*'}</h6>}
      <select id={props.id} ref={ref} type={props.name?props.type||props.name.replace(/\s/,'').toLowerCase():''} defaultValue={props.defaultValue} placeholder={props.placeholder}>
        {props.options&&props.options.map(option=>{
          return(<option>{option}</option>)
        })}
      </select>
    </SDiv>
  )
})

export const NamedToggle=React.forwardRef((props,ref)=>{
  return (
    <SDiv style={{...props.style,width:'fit-content',margin:'0.5rem auto 0 auto'}}>
      {props.name&&<h6>{props.name}{(props.r||props.required)&&'*'}</h6>}
      <input checked={props.checked} value={props.value} id={props.id} ref={ref} type='checkbox' defaultValue={props.defaultValue} placeholder={props.placeholder}/>
    </SDiv>
  )
})
export default NamedInput;