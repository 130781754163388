export const channels=[
  'Animals',
  'Culture',
  'Documentaries',
  'Funny',
  'Good News',
  'Hobbies',
  'Health & Beauty',
  'History',
  'Love',
  'Nostalgia',
  'Reality TV',
  'Science',
  'Sports',
  'Travel'
];
export const userTypes=[
  'user',
  'curator',
  'admin'
]
export const videoDetails=[
  'title',
  'description',
  'link',
  'views',
  'channel',
  'startDate',
  'subscribers',
  
]