import React from 'react';
import {useParams} from 'react-router-dom';
import {List} from '../components/list';
import style from '../styles';
import styled from 'styled-components';
import {FaYoutube,FaMinusCircle,FaPlusCircle,FaCog} from 'react-icons/fa';
import {remove,create,update} from '../redux/videos/actions';
import Page from './page';
import {channels} from '../config/nowus';
import PageSection from '../components/pageSection';
import moment from 'moment';
import {GridItem,Grid} from '../components/grid';
import Accordian from '../components/accordianCollapse';
import {connect,useDispatch, useSelector} from 'react-redux';
import {compose} from 'redux';
import {firestoreConnect, useFirestoreConnect} from 'react-redux-firebase';
import NamedInput,{NamedSelect} from '../components/namedInput';
import Button from '../components/button';

export const VideoList=({videos}) => {
  return (
    <List flex={{direction: 'row'}}>
      {videos.length!==0?videos.map(video => {
        return (
          <VideoCard key={video.id} {...video} />
        );
      }):(<SVideoCard>
        <h5>No Videos</h5>
        <h6>In This Channel</h6>
      </SVideoCard>)}
    </List>
  );
};

const SVideoCard=styled.li`
  position:relative;
  padding:1rem;
  background-color:${style.constants.colors.gray};
  min-width:15rem;
  display:block;
  svg{
    cursor:pointer;
  }
  #deleteIcon{
    z-index: 20;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
  }
  #editIcon{
    z-index: 20;
    position: absolute;
    top: 0;
    right: 1.25rem;
    margin: 0.5rem;
  }
`;
export const VideoCard=connect(null,dispatch => {
  return {
    remove: (id) => {dispatch(remove(id));}
  };
})(props => {
  let {name,link,channel,startDate,endDate}=props;
  return (
    <SVideoCard style={{...props.style}} key={props.id}>
      <h5>{name}</h5>
      <h6>{channel}</h6>
      <a href={`/video/${props.id}`}>{props.id}</a><br />
      {moment(startDate).format('MMM YYYY, dddd Do')} - {endDate? moment(endDate).format('MMM YYYY, dddd Do'):'No End Date'} <a href={link}><FaYoutube /></a>
      <FaMinusCircle id='deleteIcon' onClick={() => {props.remove(props.id);}} />
      <a id='editIcon' href={`/video/${props.id}`}><FaCog /></a>
    </SVideoCard>
  );
});

const SChannelViewer=styled.div`
  ${SVideoCard}{
    border-radius:10px;
    margin-bottom:1rem;
    margin-inline-end:1rem;
  }
`;
export const ChannelViewer=connect(null,dispatch => {
  return {
    create: (info) => {dispatch(create(info));}
  };
})(props => {
  return (
    <SChannelViewer>
      <GridItem >
        <Accordian title={(() => {
          return (<React.Fragment>{`${props.channel} - ${props.videos.length||'0'}`} <FaPlusCircle style={{zIndex: '30'}} onClick={() => {
            props.create({
              channel: props.channel.toLowerCase(),
              name: 'New Video',
              startDate:moment().format('YYYY-MM-DD')
            });
          }} /></React.Fragment>);
        })()}>
          <VideoList videos={props.videos} />
        </Accordian>
      </GridItem>
    </SChannelViewer>
  );
});


export const ChannelsWithVideos=compose(
  connect(state => {
    return {
      uid: state.firebase.auth.uid,
      profile: state.firebase.profile,
      videos: state.firestore.data.videos
    };
  }),
  firestoreConnect(props => [{collection: 'videos',where: props.profile.type==='admin'? ['createdOn','>',0]:['authorId','==',props.uid]}]))
  (props => {
    let videos;
    let videoKeys;
    let videoValues;
    console.log('props.videos',props.videos)
    if(props.videos===null){
      videos=channels.map(channel => {
        return [];
      });
      console.log('videos',videos);
    }
    if(props.videos) {
      videoKeys=Object.keys(props.videos);
      videoValues=(Object.values(props.videos)).map((video,i) => {return {...video,id: videoKeys[i]};});
      videos=channels.map(channel => {
        return videoValues.filter(video => video.channel&&video.channel.toLowerCase()===channel.toLowerCase());
      });
      console.log('videos',videos);
    }
    return (
      <List style={{height: '60vh',backgroundColor: style.constants.colors.gray,padding: '0.5rem'}}>
        {videos&&channels.map((channel,i) => {
          return (
            <ChannelViewer channel={channel} videos={videos[i]} />
          );
        })}
      </List>
    );
  });


export const VideoInfo=props => {
  useFirestoreConnect([{collection: 'videos',doc: props.id}])
  const info = useSelector(({ firestore: { data } }) => data.videos && data.videos[props.id]);
  return info? (
    <React.Fragment>
      <VideoCard {...info} />
    </React.Fragment>
  ):(<React.Fragment>Loading</React.Fragment>);
};

export const VideoConsole=props => {
  const {id}=props;
  const dispatch=useDispatch();
  const nameRef=React.createRef();
  const linkRef=React.createRef();
  const channelRef=React.createRef();
  const startDateRef=React.createRef();
  const endDateRef=React.createRef();
  const handleUpdate=id => {
    const info={
      ...nameRef.current.value&&{name:nameRef.current.value},
      ...linkRef.current.value&&{link: linkRef.current.value},
      ...channelRef.current.selectedIndex&&{channel: channels[channelRef.current.selectedIndex].toLowerCase()},
      ...startDateRef.current.value&&{startDate: startDateRef.current.value},
      ...endDateRef.current.value&&{endDate: endDateRef.current.value}
    };
    return Object.values(info).length&&dispatch(update(id,info));
  };
  return (
    <div style={{padding: '1.875rem'}}>
      <h3>Update Video Info</h3>
      <Grid c='2' style={{overflow: "hidden"}}>
        <GridItem cs='2'><NamedInput ref={nameRef} name='Name' /></GridItem>
        <NamedInput ref={linkRef} name='Link' />
        <NamedSelect options={channels} ref={channelRef} name='Channel' />
        <NamedInput ref={startDateRef} name='Start Date' type='date' />
        <NamedInput ref={endDateRef} name='End Date' type='date' />
        <GridItem cs='2'><Button><h4 onClick={()=>{handleUpdate(id)}}>Update</h4></Button></GridItem>
      </Grid>
    </div>
  );
};

const Video=props => {
  const {id}=useParams();
  return (
    <Page defaultHeader={{relative: true}} defaultFooter>
      <PageSection>
        <VideoInfo id={id} />
        <VideoConsole id={id} />
      </PageSection>
    </Page>
  );
};

export default Video;
