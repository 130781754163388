import React from 'react'
import style from '../styles'
import styled from 'styled-components';

export const SGridItem=styled.div `
  height:fit-content;
  ${props=>{return style.mixins.gridItem({
    rowSpan:props.rowSpan||props.rs,
    columnSpan:props.columnSpan||props.cs,
    justify:props.justify||props.j,
    align:props.align||props.a
  })}}
`

export const SGrid=styled.div `
  box-sizing:border-box;
  height:100%;
  overflow:auto;
  ${props=>{return style.mixins.grid({
    rows:props.rows||props.r,
    columns:props.columns||props.c,
    gap:props.gap||props.g||'1rem'
  })}}
  ${style.mixins.media(style.mixins.mediaSmall,style.mixins.grid({columns:'1'}))}
`

export const GridItem=props=>{
  return(
    <SGridItem {...props}>
      {props.children}
    </SGridItem>
  )
}

export const Grid = props => {
  return (
    <SGrid {...props}>
      {props.children}
    </SGrid>
  )
}

export default Grid
