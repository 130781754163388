import types from './types';


export const login=({email,password}) => {
  return (dispatch,{getFirebase}) => {
    const firebase=getFirebase();
    firebase.auth().signInWithEmailAndPassword(email,password)
      .then((user)=>{firebase.analytics().logEvent('login',{method:user.credential.signInMethod})})
      .then(() => {dispatch({type: types.LOGIN_SUCCESS});})
      .then(()=>resetError())
      .catch((err) => {dispatch({type: types.LOGIN_ERROR,err});});
  };
};
export const checkBillingOrder=()=>{
  return(dispatch,getState,{getFirebase})=>{
    console.log('CHECKING BILLING ORDER')
    const {orderId}=getState().firebase.profile;
    if(!orderId){return 0}
    const http=new XMLHttpRequest();
    http.open("GET", 'https://us-central1-nowus-statonfoundation.cloudfunctions.net/expressApp/paypalBilling?id='+orderId);
    http.onload=function(){
      const billingInfo=JSON.parse(http.responseText);
      console.log(billingInfo)
      dispatch({type:types.CHECK_BILLING_SUCCESS,payload:billingInfo.state})
      console.log(billingInfo)
    }
    http.send();
    console.log('sent')
  }  
}

export const logout=() => {
  return (dispatch,getState,{getFirebase}) => {
    const firebase=getFirebase();
    firebase.auth().signOut()
    .then(() => {dispatch({type: types.LOGOUT_SUCCESS});})
    .then(()=>resetError());
  };
};

export const register=(credentials,info,next) => {
  return (dispatch,getState,{getFirebase}) => {
    const firebase=getFirebase();
    const firestore=firebase.firestore();
    firebase.auth().createUserWithEmailAndPassword(credentials.email,credentials.password)
      .then((result) => {
        return firestore.collection('users').doc(result.user.uid).set({
          ...info,
          id:result.user.uid
        });
      })
      .then(()=>{firebase.analytics().logEvent('sign_up')})
      .then(() => {
        dispatch({type: types.REGISTER_USER_SUCCESS});
        next(1)
      })
      .then(()=>resetError())
      .catch((err) => {
        dispatch({type: types.REGISTER_ERROR,err});
        next(0)
      });
  };
};

export const update=(id,info) => {
  return (dispatch,getState,{getFirebase,getFirestore}) => {
    const firestore=getFirebase().firestore();
    firestore.collection('users').doc(id).update(info)
      .then(() => {dispatch({type: types.UPDATE_USER_SUCCESS});})
      .then(()=>resetError())
      .catch((err) => {dispatch({type: types.UPDATE_USER_ERROR,err});});
  };
};

export const sendResetPasswordEmail=(email)=>{
  return (dispatch,getState,{getFirebase})=>{
    const auth=getFirebase().auth();
    return auth.sendPasswordResetEmail(email)
      .then(()=>dispatch({type:types.PASSWORD_RESET_SUCCESS,payload:true}))
      .then(()=>resetError())
      .catch(err=>dispatch({type:types.PASSWORD_RESET_ERROR,err}))
  }
}

export const resetError=()=>{
  return(dispatch,getState)=>{
    dispatch({type:types.RESET_ERROR});
  }
}