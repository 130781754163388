import React from 'react';
import styled from 'styled-components';
import style from '../styles';
import Navbar from './navbar';
// import {ReactSVG} from 'react-svg';
import {Link} from 'react-router-dom';
// import {rgba} from 'polished';
const SHeader=styled.header`
  box-sizing:border-box;
  ${props=>props.mobile!==false&&style.mixins.media(style.mixins.mediaSmall,`
    overflow:visible;
    position:relative;
  `)}
  ${style.mixins.flex({align: 'center',justify: 'space-between',direction: 'row'})}
  position:fixed;
  top:0rem;
  left:0;
  right:0;
  background-color:${style.constants.colors.white};
  overflow:hidden;
  width:100%;
  ${props => props.relative&&`
    position:relative;
    background-color:${style.constants.colors.white};
    left:0;
    right:0;`
  }
  z-index:100;
  #logo{
    height:4rem;
    padding:1rem;
    ${style.mixins.media(style.mixins.mediaSmall,`
      padding:1rem 0;
    `)}
  }
  #logo2{
    ${style.mixins.media(style.mixins.mediaSmall,`
      display:none;
    `)}
    height:4rem;
    padding:1rem;
    top:0;
  }
  svg{
    ${props => props.color? `fill:${props.color};`:`fill:${style.constants.colors.white};`}
    ${props => props.svgColor&&`fill:${props.svgColor};`}
  }
  ul svg{
    padding:.3rem 0;
    ${props => props.navColor&&`fill:${props.navColor};`}
  }
  padding:0 1rem;
`;

const Header=(props) => {
  let second_logo = null;
  if(props.watchPage){
    second_logo = props.watchPage.path
  }else{
    second_logo = null;
  }
  return (
    <SHeader {...props}>
      <Link to="/"><img src={props.logo} id='logo' alt= 'logo' /></Link>
      {
        second_logo === '/watch'?null:<Link ><img src={props.logo2} id='logo2' alt= 'logo' /></Link>
      }
      <Navbar mobile={props.mobile} {...props.navbar} />
    </SHeader>
  );
};

export default Header;