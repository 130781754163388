import React from "react";
import logo from "../resources/svgs/NowUsCircleLogo.svg";
import logo2 from "../resources/svgs/Middle.svg";
import spinner from "../resources/rolling.svg";
import style from "../styles";
export const defaultLogo = logo;
export const defaultLogo2 = logo2;
export const Loading = spinner;
export const defaultNavbar = {
  color: style.constants.colors.black,
  items: [
    { title: "HOME", href: "/" },
    { title: "WATCH", href: "/watch" },

    { title: "INFLUENCERS", href: "/creators" },
    { title: "BRANDS", href: "/branding" },
    {
      title: "LOGIN",
      href: "/login",
      filter: (props) => {
        return props.auth;
      },
    },
    {
      title: "ACCOUNT",
      href: "/account",
      filter: (props) => {
        return !props.auth;
      },
    },
  ],
};
export const defaultHeader = {
  navbar: defaultNavbar,
  logo: defaultLogo,
  logo2: defaultLogo2,
};
export const defaultFooter = {
  lists: [
    {
      items: [
        { title: "FAQ", href: "/faq" },

        { title: "Contact", href: "/contact" },
      ],
    },
    {
      items: [
        { title: "Terms of Use", href: "/tou" },
        { title: "Privacy", href: "/privacy" },
      ],
    },
    {
      items: [
        { title: "Influencers", href: "/creators" },
        { title: "Brands", href: "/branding" },
      ],
    },
    {
      items: [
        { title: "Advertise", href: "/advertise" },
        // { title: "Rewards", href: "/reward" },
      ],
    },
    // {
    //   items: [{ title: "Frequent Watcher Program", href: "/fwp" }],
    // },
  ],
  bottomText: (() => (
    <div>
      © 2022. NowUs LLC. All Rights Reserved. Developed By Rixosys
      <i style={{ textDecoration: "none", fontFamily: "Arial" }}>™</i>
    </div>
  ))(),
};
