/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import style from "../styles";
import styled from "styled-components";
import { FaCog, FaLongArrowAltLeft, FaPencilAlt } from "react-icons/fa";
import Page from "./page";
import { update, sendResetPasswordEmail } from "../redux/auth/actions";
import { logout } from "../redux/user/user.actions";
import { userTypes } from "../config/nowus";
import PageSection from "../components/pageSection";
import Grid, { GridItem } from "../components/grid";
import { useSelector, useDispatch } from "react-redux";
import { useFirestoreConnect } from "react-redux-firebase";
import NamedInput, { NamedSelect } from "../components/namedInput";
import Button from "../components/button";
import List from "../components/list";
import Modal from "../components/modal";
import Cs from "../components/colorSelect";
import DatePicker from "react-datepicker";
import {
  updateUser,
  updateBirth,
  cancelAccount,
} from "../redux/user/user.actions";
import axios from "axios";
import moment from "moment";

export const SUserCard = styled.li`
  position: relative;
  padding: 1rem;
  background-color: ${style.constants.colors.gray};
  min-width: 15rem;
  display: block;
  #editIcon {
    z-index: 20;
    position: absolute;
    top: 0;
    right: 0;
    margin: 0.5rem;
  }
  h4 {
    text-transform: capitalize;
    sup {
      text-transform: lowercase;
    }
  }
`;

export const UserCard = (props) => {
  if (!props.id) {
    return <React.Fragment>Loading...</React.Fragment>;
  }
  return (
    <SUserCard>
      <h6>{props.orderId ? "Paid" : "UnPaid"}</h6>
      <h4>
        {props.firstName} {props.lastName}
        <sup>{props.type}</sup>
      </h4>
      {props.address}
      <br />
      {props.city}, {props.state} - {props.zipcode}
      <br />
      {props.email}
      <br />
      {props.birth}
      <a id="editIcon" href={`/user/${props.id}`}>
        <FaCog />
      </a>
    </SUserCard>
  );
};

export const UserInfo = (props) => {
  const { id } = props;
  useFirestoreConnect([{ collection: "users", doc: id }]);
  const user = useSelector(
    ({ firestore: { data } }) => data.users && data.users[id]
  );
  return <UserCard {...user} />;
};

export const UserConsole = (props) => {
  const [passwordResetSent, setPasswordResetSent] = React.useState(false);
  const { id } = props;
  useFirestoreConnect([{ collection: "users", doc: id }]);
  const user = useSelector(
    ({ firestore: { data } }) => data.users && data.users[id]
  );
  const dispatch = useDispatch();
  const firstNameRef = React.createRef();
  const lastNameRef = React.createRef();
  const cityRef = React.createRef();
  const stateRef = React.createRef();
  const zipcodeRef = React.createRef();
  const birthRef = React.createRef();
  const typeRef = React.createRef();
  const addressRef = React.createRef();
  const handleUpdate = (id) => {
    const info = {
      ...(firstNameRef.current.value && {
        firstName: firstNameRef.current.value,
      }),
      ...(lastNameRef.current.value && { lastName: lastNameRef.current.value }),
      ...(addressRef.current.value && { address: addressRef.current.value }),
      ...(cityRef.current.value && { city: cityRef.current.value }),
      ...(stateRef.current.value && { state: stateRef.current.value }),
      ...(zipcodeRef.current.value && { zipcode: zipcodeRef.current.value }),
      ...(birthRef.current.value && { birth: birthRef.current.value }),
      ...((typeRef.current.selectedIndex ||
        typeRef.current.selectedIndex !== 0) && {
        type: userTypes[typeRef.current.selectedIndex - 1],
      }),
    };
    return Object.values(info).length && dispatch(update(id, info));
  };
  return (
    <div style={{ padding: "1.875rem" }}>
      <h3>Update User Info</h3>
      <Grid c="3" style={{ overflow: "hidden" }}>
        <GridItem cs="2">
          <NamedInput ref={firstNameRef} name="First Name" />
        </GridItem>
        <NamedInput ref={lastNameRef} name="Last Name" />
        <GridItem cs="3">
          <NamedInput ref={addressRef} name="Address" />
        </GridItem>
        <NamedInput ref={cityRef} name="City" />
        <NamedInput ref={stateRef} name="State" />
        <NamedInput ref={zipcodeRef} name="Zipcode" />
        <GridItem cs="2">
          <NamedInput ref={birthRef} name="Date Of Birth" type="month" />
        </GridItem>
        <NamedSelect
          ref={typeRef}
          name="Type"
          options={["Select A User Type", ...userTypes]}
        />
        <GridItem cs="3">
          <Button style={{ width: "100%" }}>
            <h4
              onClick={() => {
                handleUpdate(id);
              }}
            >
              Update
            </h4>
          </Button>
        </GridItem>
        <Button
          onClick={() => {
            if (!passwordResetSent) {
              dispatch(sendResetPasswordEmail(user.email));
              setPasswordResetSent(true);
            }
          }}
        >
          <h6>{!passwordResetSent ? "Send Reset Password Email" : "Sent"}</h6>
        </Button>
      </Grid>
    </div>
  );
};

export const AccountCard = (props) => {
  const profile = useSelector((state) => state.currentUser.user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [points, setPoints] = useState(0);
  const [redeemId, setRedeemId] = useState(0);
  const [items, setItems] = useState(null);
  const [redeemErr, setRedeemErr] = useState(null);
  const [startDate, setStartDate] = useState(moment(profile.birth).toDate());
  const newBirthRef = React.createRef();
  const newFirstNameRef = React.createRef();
  const newLastNameRef = React.createRef();

  useEffect(() => {
    const fetchRedeemPoints = async () => {
      let redeemPoints = await axios.get(
        "https://admin.nowus.com/api/user/get-redeem-points/" + profile.id
      );

      console.log(redeemPoints)
      if (redeemPoints.data.data.points) {
        setPoints(redeemPoints.data.data.points);
      } else {
        setPoints(redeemPoints.data.data);
      }
    };

    fetchRedeemPoints();
  }, []);

  useEffect(() => {
    const fetchRedeemItems = async () => {
      axios
        .get("https://admin.nowus.com/api/redeem/items")
        .then((res) => {
          setItems(res.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    };
    fetchRedeemItems();
  }, []);

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const UpdateBirth = (props) => (
    <React.Fragment>
      <h4 style={{ marginBottom: "3rem" }}>Update Birth</h4>
      {/* <NamedInput name="New Birth Month" type="month" ref={newBirthRef} /> */}
      <DatePicker
        selected={startDate}
        onChange={handleDateChange}
        required
        name="Date Of Birth"
        dateFormat="MM/dd/yyyy"
      />
      <Button
        onClick={() => {
          dispatch(
            updateBirth({
              id: profile.id,
              ...(startDate ? { birth: startDate } : undefined),
            })
          );
          props.closeModal()();
        }}
        style={{ width: "100%" }}
      >
        <h5>Update</h5>
      </Button>
    </React.Fragment>
  );
  const UpdateName = (props) => (
    <React.Fragment>
      <h4 style={{ marginBottom: "3rem" }}>Update Name</h4>
      <NamedInput
        name="New First Name"
        type="First Name"
        ref={newFirstNameRef}
      />
      <NamedInput
        name="New Last Name"
        type="Last Name"
        ref={newLastNameRef}
      ></NamedInput>
      <Button
        onClick={() => {
          dispatch(
            updateUser({
              id: profile.id,
              ...(newFirstNameRef.current.value
                ? { firstName: newFirstNameRef.current.value.toLowerCase() }
                : undefined),
              ...(newLastNameRef.current.value
                ? { lastName: newLastNameRef.current.value.toLowerCase() }
                : undefined),
            })
          );
          props.closeModal()();
        }}
        style={{ width: "100%" }}
      >
        <h5>Update</h5>
      </Button>
    </React.Fragment>
  );

  const deleteAccount = (user_id) => {
    dispatch(cancelAccount(user_id))
      .then((res) => {
        history.push("/login");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRedeem = async (id, point) => {
    let data = {
      user_id: profile.id,
      points: point,
    };
    if (parseInt(points) >= parseInt(point)) {
      let redeem = await axios.post(
        "https://admin.nowus.com/api/redeem/items/" + id,
        data
      );
      setRedeemId(id);
      setPoints(redeem.data[0].points);
    } else {
      setRedeemErr("You have not enough points to redeem");
    }
  };
  return (
    <GridItem
      className="accountInfo"
      style={{
        backgroundColor: "#c0c0c0a6",
        padding: "50px",
        borderRadius: "20px",
        textAlign:"center"
      }}
    >
      <List flex={{ justify: "space-between", direction: "column" }}>
        <Link to="/">
          <p>
            <List flex={{ direction: "row", align: "center" }}>
              <FaLongArrowAltLeft style={{ marginRight: ".5rem" }} /> Return
            </List>
          </p>
        </Link>
        <br />
        <p>Your Dashboard</p>
        <h5 style={{ lineHeight: 0.8, textTransform: "capitalize",
    fontFamily: 'Helvetica' }}>
          {profile.firstName} {profile.lastName}
          {/* <sup style={{ fontWeight: "900", color: "orange" }}>user</sup> */}
          <br />
          <Modal
            style={{ padding: "1rem" }}
            buttonStyle={{
              border: 0,
              backgroundColor: "white",
              width: "fit-content",
              padding: 0,
              marginTop: -10,
            }}
            button={
              <h6 style={{ fontSize: 14, margin: 0, padding: "3px" }}>
                Change Name
              </h6>
            }
          >
            <UpdateName />
          </Modal>
        </h5>

        <h6 style={{ margin: 0, color: "white" }}>{profile.email}</h6>
        <span style={{ fontSize: "14px", color: "white" }}>
          {}
          {moment(profile.birth).format("DD MMM , YYYY")}
          <Modal
            style={{ padding: "1rem" }}
            buttonStyle={{ border: 0, backgroundColor: "white" }}
            button={<FaPencilAlt />}
          >
            <UpdateBirth />
          </Modal>
        </span>
        <div style={{ display: "inline", color: "#39c5f7" }}>
          <Link
            to="/"
            onClick={() => {
              dispatch(logout());
            }}
          >
            <button
              style={{
                color: "#fff",
                backgroundColor: "#ff0000a8",
                border: "none",
                padding: "5px",
              }}
            >
              Logout
            </button>
          </Link>{" "}
          <Link to="/recoverpassword">
            <button
              style={{
                color: "#fff",
                backgroundColor: "#004B84",
                border: "none",
                padding: "5px",
              }}
            >
              Reset Password
            </button>
          </Link>
          {/* <a href='https://www.paypal.com/billing/subscriptions'></a> */}
        </div>
        <br />
        <button
          onClick={() => deleteAccount(profile.id)}
          style={{
            color: "#fff",
            backgroundColor: "#ff0000a8",
            border: "none",
            padding: "5px",
            width:"50%",
            margin:"auto"
          }}
        >
          Cancel Account
        </button>
      </List>
      <br />
      <p style={{ color: "#fff", border: "none",fontWeight:"bolder"}}>
        REDEEM POINTS :- {points ? points : 0}
      </p>
      <br/>

      <button
        type="button"
        data-toggle="modal"
        data-target="#staticBackdrop"
        style={{
          color: "#fff",
          backgroundColor: "#004B84",
          border: "none",
          padding: "6px",
          fontSize: "11px",
        }}
      >
        Click here to redeem your NowUs frequent watching points
      </button>

      <div
        class="modal fade"
        id="staticBackdrop"
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div
            class="modal-content"
            style={{ overflowY: "scroll", height: "600px" }}
          >
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">
                REDEEM ITEMS
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div>
                <div className="navbar-nav" style={{ display: "inline" }}>
                  {items !== null ? (
                    items.map((item) => (
                      <React.Fragment>
                        <div className="row" key={item.id}>
                          <div className="col-md-6">
                            <img
                              alt="Image"
                              style={{ height: "100px", width: "200px" }}
                              src={item.redeem_image}
                            />
                          </div>
                          <div className="col-md-6">
                            <p
                              className="nav-link"
                              style={{ fontSize: "12px", marginLeft: "5px" }}
                            >
                              {item.description}

                              <p style={{ color: "red", fontSize: "16px" }}>
                                Points :- {item.points}
                              </p>

                              <p>
                                <button
                                  onClick={() =>
                                    handleRedeem(item.id, item.points)
                                  }
                                  style={{
                                    height: "35px",
                                    marginTop: "40px",
                                    color: "#fff",
                                    backgroundColor: "#004B84",
                                    border: "none",
                                    padding: "6px",
                                  }}
                                >
                                  {redeemId === item.id ? "REDEEMED" : "REDEEM"}
                                </button>
                              </p>
                            </p>
                          </div>
                        </div>
                        <br />
                      </React.Fragment>
                    ))
                  ) : (
                    <div>No items found</div>
                  )}
                </div>
              </div>
              {/* <img src=""/> */}
            </div>
            <div class="modal-footer">
              {redeemErr && (
                <h6>
                  <Cs color="red">{redeemErr}</Cs>
                </h6>
              )}
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </GridItem>
  );
};

const User = (props) => {
  const { id } = useParams();
  return (
    <Page defaultHeader={{ relative: true }} defaultFooter>
      <PageSection size="fit-content">
        <UserInfo id={id} />
        <UserConsole id={id} />
      </PageSection>
    </Page>
  );
};

export default User;
