import {createGlobalStyle} from 'styled-components';

import Hndr from '../../resources/fonts/Helvetica Now Display/HelveticaNowDisplay-Regular.ttf';
import Hndl from '../../resources/fonts/Helvetica Now Display/HelveticaNowDisplay-LightIta.ttf';
import Hndb from '../../resources/fonts/Helvetica Now Display/HelveticaNowDisplay-Bold.ttf';
import HndB from '../../resources/fonts/Helvetica Now Display/HelveticaNowDisplay-Black.ttf';
import Karla from '../../resources/fonts/Karla/Karla-Regular.ttf';

export const header='"Helvetica"';
export const paragraph='"Karla"';

export const FontFace=createGlobalStyle`
  @font-face{
    font-family:${header};
    src: url(${Hndr}) format('truetype');
    font-weight: normal;
  }
  @font-face{
    font-family:${header};
    src:url(${Hndl}) format('truetype');
    font-weight:light;
  }
  @font-face{
    font-family:${header};
    src:url(${Hndb}) format('truetype');
    font-weight:bold;
  }
  @font-face{
    font-family:${header};
    src:url(${HndB}) format('truetype');
    font-weight:bolder;
  }
  @font-face{
    font-family:${paragraph};
    src:url(${Karla}) format('truetype');
    font-weight:normal;
  }
`;

export default Object.freeze({
  header,
  paragraph,
  FontFace
});