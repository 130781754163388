import React from 'react';
import style from '../styles';

//Pages
import Page from './page';
import IntroF from '../components/fullscreen/introF';
import DividerF from '../components/fullscreen/dividerF';
import Button from '../components/button';

//Images
import DividerBackground from '../resources/dividerbackground.png';
import foreveryoungImg from '../resources/PricesMonthly.jpg';
import couplegoingforadriveImg from '../resources/couplegoingforadrive.jpg';
import radicalgrandparentsImg from '../resources/PricesYearly.jpg';

const Membership=(props) => {
  return (
    <Page defaultHeader defaultFooter title="NowUs - Memberships">
      <IntroF 
        style={{color: style.constants.colors.white,paddingTop:'100px'}}
        img={couplegoingforadriveImg}
      >
        <h2>Two week<br />FREE trial!</h2>
        <p>Included with either membership plan.
        <br />Pick your price, pick your plan.
        <br />It's that easy!
        </p>
        <h6 style={{marginTop: '0.5rem'}}>{"( Scroll Down For More Info )"}</h6>
      </IntroF>

      <DividerF
        height='10rem'
        img={DividerBackground}
        color={style.constants.colors.white}
      />


      <IntroF
        img={foreveryoungImg}
        style={{color:'black'}}
        bgColor={style.constants.colors.primary}
        sidebarColor={style.constants.colors.secondary}
        side='right'
      >
        <h3 style={{color:'transparent','-webkit-text-stroke': '3px black'}}> MONTHLY <br /> PLAN</h3>
        <p>
          WEEK FREE TRIAL<br/>
          ONLY $3.99 PER MONTH<br/>
          <ul>
            <li>• 1 account.</li>
            <li style={{marginTop: '0.5rem'}}>• Can Watch 24/7.</li>
            <li style={{marginTop: '0.5rem'}}>• Curated content/entertainment for people<br /> 50plus young at heart.</li>
            <li style={{marginTop: '0.5rem'}}>• 12+ Curated channels include: <br />Celebrations, Culture, DIY, Documentaries, <br /> Funny, Health, History, Love, News, <br />Nostalgia, Pets, Science, Sports and Travel.</li>
            <li style={{marginTop: '0.5rem'}}>• HD streaming of all the most popular content.</li>
          </ul>
        </p>
        <Button to='/register' style={{width: '100%',marginTop: '5%'}}><h5>CLICK TO SIGN UP TODAY</h5></Button>
      </IntroF>

      <DividerF
        height='5rem'
        color={style.constants.colors.primary}
      />

      <IntroF
        img={radicalgrandparentsImg}
        style={{color:style.constants.colors.white}}
      >
        <h3 style={{color:'transparent','-webkit-text-stroke': '3px white'}}>YEARLY <br /> PLAN</h3>
        <p>2-WEEK FREE TRIAL</p>
        <p>SAVE 20% WITH YEARLY ACCOUNT</p>
        <p>$37.99 PER YEAR</p>
        <p>
          <ul>
            <li>• 1 account.</li>
            <li style={{marginTop: '0.5rem'}}>• Can Watch 24/7.</li>
            <li style={{marginTop: '0.5rem'}}>• Curated content/entertainment for people <br /> 50plus young at heart.</li>
            <li style={{marginTop: '0.5rem'}}>• 12+ Curated channels include: <br />Celebrations, Culture, DIY, Documentaries, <br /> Funny, Health, History, Love, News, Nostalgia,<br /> Pets, Science, Sports and Travel.</li>
            <li style={{marginTop: '0.5rem'}}>• HD streaming of all the most popular content.</li>
          </ul>
        </p>
        <Button to='/register' style={{width: '100%',marginTop: '5%'}}><h5>CLICK TO SIGN UP TODAY</h5></Button>
      </IntroF>


      <h6 style={{textAlign: 'center'}}>
        <br /> BOTH PLAN'S INCLUDE FIRST TWO WEEKS FREE FOR NEW MEMBERS.
        <br /><br />* IF YOU CHOOSE TO REMAIN A MEMBER OF NOWUS, RECURRING BILLING WILL OCCUR MONTHLY OR YEARLY (BASED ON PLAN SELECTED) ON THE DATE THAT YOUR TWO WEEK FREE TRIAL ENDS.
        <br /><br />YOU CAN CHANGE OR UPGRADE YOUR PLAN ANYTIME FROM YOUR ACCOUNT PAGE ONCE YOU HAVE SIGNED UP.
        <br/><br/>IF YOU DON’T WANT TO BE BILLED, YOU MUST CANCEL YOUR PLAN BEFORE THE END OF YOUR TWO-WEEK FREE TRIAL.
        <br /><br />* FREE TRIAL DURATION AND AVAILABILITY MAY VARY BY REGION.
      </h6>

    </Page>
  );
};

export default Membership;