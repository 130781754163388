import {createGlobalStyle} from 'styled-components';
import style from '../styles';
const globalStyle=createGlobalStyle`
  ::-webkit-scrollbar{
    width:10px;
    background-color:${style.constants.colors.white};
    &:horizontal{
      width:10px;
    }
  }
  ::-webkit-scrollbar-thumb{
    background-color:${style.constants.colors.gray};
    border-radius:10rem;
  }
  html{
  }
  body{
   font-family:${style.constants.fonts.paragraph};
   color:${style.constants.colors.black};
   background-color:${style.constants.colors.white};
  }
  a,ul,ol,figure,article,span,p,h1,h2,h3,h4,h5,h6,svg{
    margin:0;
    padding:0;
  }
  a{
    color:${style.constants.colors.black};
    text-decoration:none;
  }
  ul,ol{
    list-style:none;
  }
  h1,h2,h3,h4,h5,h6{
    font-family:${style.constants.fonts.header};
    font-weight:normal;
    margin-bottom:0.875rem;
  }
  h1{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.h1,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:14vw;
    `)}
  }
  h2{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.h2,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:10vw;
    `)}
  }
  h3{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.h3,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:9.5vw;
    `)}
  }
  h4{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.h4,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:8vw;
    `)}
  }
  h5{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.h5,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:3.5vw;
    `)}
  }
  h6{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.h6,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:3.3vw;
    `)}
    font-family:inherit;
    letter-spacing:0.06em;
    text-transform:uppercase;
  }
  p{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.p,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:3vw;
    `)}
  }
  span{
    ${style.mixins.responsiveValue('font-size',[style.constants.sizes.span,'px'],[0.6,1.2,1.4])}
    ${style.mixins.media(style.mixins.mediaSmall,`
      font-size:3.5vw;
    `)}
  }
  p,span{
    margin:0 0 .5rem 0;
  }
  select,input{
    ::placeholder{
      font-size: $fontSizeP;
      color: rgba($colorTitle, 0.4);
    }
    padding : 0.875rem;
    margin-bottom  : 1rem;
    border: solid 1px ${style.constants.colors.gray};
    border-radius: 2px;
    width:100%;
  }
  form{
    width:100%;
  }
  svg{
    fill:${style.constants.colors.secondary};
  }
  button:hover svg{
    fill:${style.constants.colors.primary};
  }
`;

export default globalStyle;