export const p=20;
export const span=24;
export const h1=144;
export const h2=100;
export const h3=75;
export const h4=50;
export const h5=30;
export const h6=15;


const sizes={
  p,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
};

export default sizes;