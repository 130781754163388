import React from 'react';
import style from '../styles';
import styled from 'styled-components';
import {useSelector} from 'react-redux';
import {useTable,useExpanded} from 'react-table';
import {channels} from '../config/nowus';
import {useDispatch} from 'react-redux';
import {create,update,remove} from '../redux/videos/actions';
import {Grid,GridItem} from './grid';
import {NamedInput,NamedSelect,NamedToggle} from './namedInput';
import {List} from './list';
import {Redirect} from 'react-router-dom';
import {FaPlusSquare,FaSearch} from 'react-icons/fa';
import Modal from './modal.js';
import {useFirestoreConnect} from 'react-redux-firebase';
import {AccountCard} from '../pages/user';
import moment from 'moment';
import ReactPlayer from 'react-player';

//this shit just sets the varibles for the table basicaly
//the table can get wayyy more advanced but this is just a super basic version of it
function Table({columns,data}) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  }=useTable(
    {
      columns: columns,
      data,
    },
    useExpanded // We can useExpanded to track the expanded state
    // for sub components too!
  );
  return (
    //actaully rendering the dumb ass table
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <React.Fragment>
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render('Cell')} </td>;
                })}
              </tr>
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
}

const SCuratorDash=styled.div`
  table{
    text-align:left;
    background-color: rgba(196, 196, 196, 0.2);
    height:fit-content;
    border-spacing: 0;
    border: 1px solid ${style.constants.colors.gray};
    tr {
      margin:1rem;
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th{
      position:sticky;
      top:0;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      background-color:white;
      padding:10px;
      min-width:10rem;
      :first-child{
        min-width:0;
      }
    }
    td {
      vertical-align:baseline;
      min-width:50%;
      margin: 0;
      padding: .5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;

      :last-child {
        border-right: 0;
      }
      :first-child{
        vertical-align:middle;
      }
    }
  }
  .sidebar{
    height:100vh;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    .accountInfo{
      padding:1rem;
      position:sticky;
      background-color:${style.constants.colors.white};
      z-index:110;
      top:0;
      box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
    }
    ul{
      overflow:auto;
      li{
        svg{
          justify-self:center;
        }
        border:solid rgba(196, 196, 196, 0.2) 1px;
        padding:1rem;
        margin:0.2rem;
        position:relative;
        cursor:pointer;
        &::after{
          position:absolute;
          right:0;
          top:0;
          bottom:0;
          content:'';
          width:1rem;
          height:100%;
          background-color:${style.constants.colors.gray};
        }
        &.-selected::after{
          background-color:${style.constants.colors.primary};
        }
      }
    }
  }
`;

const ChannelLi=function(props) {
  const {channel,index,selectedIndex,onClick}=props;
  return (
    <li onClick={onClick} className={index===selectedIndex? '-selected':''}><span>{channel}</span></li>
  );
};
const NewVideoPanel=function(props) {
  const titleRef=React.createRef();
  const descriptionRef=React.createRef();
  const linkRef=React.createRef();
  const channelRef=React.createRef();
  const subsRef=React.createRef();
  const viewsRef=React.createRef();
  const channel1Ref=React.createRef();
  const channel2Ref=React.createRef();
  const channel3Ref=React.createRef();
  const uploadDateRef=React.createRef();
  const startDateRef=React.createRef();
  const endDateRef=React.createRef();
  const mondayRef=React.createRef();
  const tuesdayRef=React.createRef();
  const wednesdayRef=React.createRef();
  const thursdayRef=React.createRef();
  const fridayRef=React.createRef();
  const saturdayRef=React.createRef();
  const sundayRef=React.createRef();
  const amRef=React.createRef();
  const pmRef=React.createRef();
  const refs=[
    titleRef,
    descriptionRef,
    linkRef,
    channelRef,
    subsRef,
    viewsRef,
    channel1Ref,
    channel2Ref,
    channel3Ref,
    uploadDateRef,
    startDateRef,
    endDateRef,
    mondayRef,
    tuesdayRef,
    wednesdayRef,
    thursdayRef,
    fridayRef,
    saturdayRef,
    sundayRef,
    amRef,
    pmRef
  ]
  const getInputValues=() => {
    const _channels=[(channel1Ref.current.selectedIndex==0||channel1Ref.current.selectedIndex==undefined)?'':channels[channel1Ref.current.selectedIndex-1],(channel2Ref.current.selectedIndex===0||channel2Ref.current.selectedIndex===undefined)?'':channels[channel2Ref.current.selectedIndex-1],(channel3Ref.current.selectedIndex===0||channel3Ref.current.selectedIndex==undefined)?'':channels[channel3Ref.current.selectedIndex-1]]
    const values={
      ...titleRef.current.value&&{title: titleRef.current.value},
      ...descriptionRef.current.value&&{description: descriptionRef.current.value},
      ...linkRef.current.value&&{link: linkRef.current.value},
      ...channelRef.current.value&&{channel: channelRef.current.value},
      channels:_channels,
      ...uploadDateRef.current.value&&{uploadDate: uploadDateRef.current.value},
      ...subsRef.current.value&&{subs: subsRef.current.value},
      ...viewsRef.current.value&&{views: viewsRef.current.value},
      ...startDateRef.current.value&&{startDate: startDateRef.current.value},
      ...endDateRef.current.value&&{endDate: endDateRef.current.value},
      broadcastDays: [mondayRef.current.checked.toString(),tuesdayRef.current.checked.toString(),wednesdayRef.current.checked.toString(),thursdayRef.current.checked.toString(),fridayRef.current.checked.toString(),saturdayRef.current.checked.toString(),sundayRef.current.checked.toString()],
      broadcastTimeframes: [amRef.current.checked.toString(),pmRef.current.checked.toString()]
    }
    return values;
  };
  const dispatch=useDispatch();
  const profile=useSelector(state => state.firebase.profile);
  const [error, setError] = React.useState(null);
  const [link, setLink] = React.useState(null);
  const addVideo=() => {
    dispatch(create({
      ...getInputValues()
    }));
    props.closeModal(()=>{refs.map(ref=>{
      ref.current.value='';
      ref.current.selectedIndex=0;
      ref.current.checked=true;
      setLink(null);
    })})()
  };
  React.useEffect(() => {
    mondayRef.current.checked=true
    tuesdayRef.current.checked=true
    wednesdayRef.current.checked=true
    thursdayRef.current.checked=true
    fridayRef.current.checked=true
    saturdayRef.current.checked=true
    sundayRef.current.checked=true
    amRef.current.checked=true
    pmRef.current.checked=true
    startDateRef.current.value=moment().format('YYYY-MM-DD');
  })
  return (
    <Grid c='2' gap='1rem' style={{boxSizing: 'border-box',height: '100%',width: '100%',backgroundColor: style.constants.colors.white,borderRadius: '1rem 0 0 1rem',padding: '1rem'}}>
      <GridItem cs='2'>
        <h4>ADD VIDEO</h4>
      </GridItem>
      <GridItem>
        <h6>Copy From <strong>Youtube</strong></h6>
      </GridItem>
      <GridItem>
        <h6><strong>NowUs</strong> Info</h6>
      </GridItem>
      <GridItem>
        <NamedInput ref={titleRef} name='Title - Video' />
        <NamedInput ref={descriptionRef} name='Description - Video' />
        <NamedInput onChange={(e)=>{setLink(e.target.value)}} required ref={linkRef} name='Link - Video' />
        <NamedInput ref={channelRef} name='YouTube Channel Name' />
        <NamedInput ref={subsRef} name='YouTube Channel Subscribers' />
        <NamedInput ref={viewsRef} name='YouTube Video Views' />
        <NamedInput ref={uploadDateRef} name='Upload Date' type='date' />
      </GridItem>
      <GridItem>
        <NamedSelect ref={channel1Ref} options={['None',...channels]} name='Select Channels' />
        <NamedSelect ref={channel2Ref} options={['None',...channels]} />
        <NamedSelect ref={channel3Ref} options={['None',...channels]} />
        <NamedInput required ref={startDateRef} name='Start Date' type='Date' />
        <NamedInput ref={endDateRef} name='End Date' type='Date' />
        <Grid c='7'>
          <NamedToggle ref={mondayRef} name='Monday' />
          <NamedToggle ref={tuesdayRef} name='Tuesday' />
          <NamedToggle ref={wednesdayRef} name='Wednesday' />
          <NamedToggle ref={thursdayRef} name='Thursday' />
          <NamedToggle ref={fridayRef} name='Friday' />
          <NamedToggle ref={saturdayRef} name='Saturday' />
          <NamedToggle ref={sundayRef} name='Sunday' />
        </Grid>
        <Grid c='2'>
          <NamedToggle ref={amRef} name='AM' />
          <NamedToggle ref={pmRef} name='PM' />
        </Grid>
      </GridItem>
      {link&&(
        <GridItem cs='2'>
          <ReactPlayer url={link} width='100%' playing={true} controls={false}/>
        </GridItem>
      )}
      <GridItem cs='2'>
        {error&&(<span>{error}</span>)}
        <button onClick={addVideo} style={{width: '100%',marginBottom: '1rem'}}><h5 style={{margin: 0}}>Add New Video</h5></button>
      </GridItem>
    </Grid>
  );
};

const EditVideo=(props)=>{
  const titleRef=React.createRef();
  const descriptionRef=React.createRef();
  const linkRef=React.createRef();
  const channelRef=React.createRef();
  const subsRef=React.createRef();
  const viewsRef=React.createRef();
  const channel1Ref=React.createRef();
  const channel2Ref=React.createRef();
  const channel3Ref=React.createRef();
  const uploadDateRef=React.createRef();
  const startDateRef=React.createRef();
  const endDateRef=React.createRef();
  const mondayRef=React.createRef();
  const tuesdayRef=React.createRef();
  const wednesdayRef=React.createRef();
  const thursdayRef=React.createRef();
  const fridayRef=React.createRef();
  const saturdayRef=React.createRef();
  const sundayRef=React.createRef();
  const amRef=React.createRef();
  const pmRef=React.createRef();
  const refs=[
    titleRef,
    descriptionRef,
    linkRef,
    channelRef,
    subsRef,
    viewsRef,
    channel1Ref,
    channel2Ref,
    channel3Ref,
    uploadDateRef,
    startDateRef,
    endDateRef,
    mondayRef,
    tuesdayRef,
    wednesdayRef,
    thursdayRef,
    fridayRef,
    saturdayRef,
    sundayRef,
    amRef,
    pmRef
  ]
  const getInputValues=() => {
    const _channels=[(channel1Ref.current.selectedIndex===0||channel1Ref.current.selectedIndex===undefined)?'':channels[channel1Ref.current.selectedIndex-1],(channel2Ref.current.selectedIndex===0||channel2Ref.current.selectedIndex===undefined)?'':channels[channel2Ref.current.selectedIndex-1],(channel3Ref.current.selectedIndex===0||channel3Ref.current.selectedIndex===undefined)?'':channels[channel3Ref.current.selectedIndex-1]]
    const values={
      ...titleRef.current.value&&{title: titleRef.current.value},
      ...descriptionRef.current.value&&{description: descriptionRef.current.value},
      ...linkRef.current.value&&{link: linkRef.current.value},
      ...channelRef.current.value&&{channel: channelRef.current.value},
      channels:_channels,
      ...uploadDateRef.current.value&&{uploadDate: uploadDateRef.current.value},
      ...subsRef.current.value&&{subs: subsRef.current.value},
      ...viewsRef.current.value&&{views: viewsRef.current.value},
      ...startDateRef.current.value&&{startDate: startDateRef.current.value},
      ...endDateRef.current.value&&{endDate: endDateRef.current.value},
      broadcastDays: [mondayRef.current.checked.toString(),tuesdayRef.current.checked.toString(),wednesdayRef.current.checked.toString(),thursdayRef.current.checked.toString(),fridayRef.current.checked.toString(),saturdayRef.current.checked.toString(),sundayRef.current.checked.toString()],
      broadcastTimeframes: [amRef.current.checked.toString(),pmRef.current.checked.toString()]
    }
    return values;
  };
  const dispatch = useDispatch();
  const video = useSelector(state => state.firestore.data.videos[props.id]);
  const UpdateVideo=()=>{
    dispatch(update(props.id,{...video,...getInputValues()}));
    props.closeModal(()=>{refs.map(ref=>{if(ref.current){
      ref.current.value='';
      ref.current.selectedIndex=0;
      ref.current.checked=false;
    }})})();
  }
  React.useEffect(() => {
    mondayRef.current.checked=video.broadcastDays&&video.broadcastDays[0]==="true"?true:false
    tuesdayRef.current.checked=video.broadcastDays&&video.broadcastDays[1]==="true"?true:false
    wednesdayRef.current.checked=video.broadcastDays&&video.broadcastDays[2]==="true"?true:false
    thursdayRef.current.checked=video.broadcastDays&&video.broadcastDays[3]==="true"?true:false
    fridayRef.current.checked=video.broadcastDays&&video.broadcastDays[4]==="true"?true:false
    saturdayRef.current.checked=video.broadcastDays&&video.broadcastDays[5]==="true"?true:false
    sundayRef.current.checked=video.broadcastDays&&video.broadcastDays[6]==="true"?true:false
    amRef.current.checked=video.broadcastTimeframes&&video.broadcastTimeframes[0]==="true"?true:false
    pmRef.current.checked=video.broadcastTimeframes&&video.broadcastTimeframes[1]==="true"?true:false
  })
  return(
    <Grid c='2' gap='1rem' style={{boxSizing: 'border-box',height: '100%',width: '100%',backgroundColor: style.constants.colors.white,borderRadius: '1rem 0 0 1rem',padding: '1rem'}}>
      <GridItem cs='2'>
        <h4>EDIT VIDEO</h4>
      </GridItem>
      <GridItem>
        <h6>Copy From <strong>Youtube</strong></h6>
      </GridItem>
      <GridItem>
        <h6><strong>NowUs</strong> Info</h6>
      </GridItem>
      <GridItem>
        <NamedInput defaultValue={video.title||''} ref={titleRef} name='Title - Video' />
        <NamedInput defaultValue={video.description||''} ref={descriptionRef} name='Description - Video' />
        <NamedInput defaultValue={video.link||''} ref={linkRef} name='Link - Video' />
        <NamedInput defaultValue={video.channel||''} ref={channelRef} name='YouTube Channel Name' />
        <NamedInput defaultValue={video.subs||''} ref={subsRef} name='YouTube Channel Subscribers' />
        <NamedInput defaultValue={video.views||''} ref={viewsRef} name='YouTube Video Views' />
        <NamedInput defaultValue={video.uploadDate||''} ref={uploadDateRef} name='Upload Date' type='date' />
      </GridItem>
      <GridItem>
        <NamedSelect defaultValue={video.channels[0]||''} ref={channel1Ref} options={['None',...channels]} name='Select Channels' />
        <NamedSelect defaultValue={video.channels[1]||''} ref={channel2Ref} options={['None',...channels]} />
        <NamedSelect defaultValue={video.channels[2]||''} ref={channel3Ref} options={['None',...channels]} />
        <NamedInput defaultValue={video.startDate||''} ref={startDateRef} name='Start Date' type='Date' />
        <NamedInput defaultValue={video.endDate||''} ref={endDateRef} name='End Date' type='Date' />
        <Grid c='7'>
          <NamedToggle ref={mondayRef} name='Monday' />
          <NamedToggle ref={tuesdayRef} name='Tuesday' />
          <NamedToggle ref={wednesdayRef} name='Wednesday' />
          <NamedToggle ref={thursdayRef} name='Thursday' />
          <NamedToggle ref={fridayRef} name='Friday' />
          <NamedToggle ref={saturdayRef} name='Saturday' />
          <NamedToggle ref={sundayRef} name='Sunday' />
        </Grid>
        <Grid c='2'>
          <NamedToggle ref={amRef} name='AM' />
          <NamedToggle ref={pmRef} name='PM' />
        </Grid>
      </GridItem>
      <GridItem cs='2'>
        <button onClick={UpdateVideo} style={{width: '100%',marginBottom: '1rem'}}><h5 style={{margin: 0}}>Update Video</h5></button>
      </GridItem>
    </Grid>
  )
};

const RemoveVideo=(props)=>{
  const dispatch = useDispatch();
  return (
    <div style={{boxSizing: 'border-box',height: '100%',width: '100%',backgroundColor: style.constants.colors.white,borderRadius: '1rem 0 0 1rem',padding: '1rem'}}>
      <List flex={{direction:'column',align:'center',justify:'center'}} style={{height:'100%',width:'100%'}}>
        <p>You Are Deleting A Video</p><br/>
        <h4>Are You Sure?</h4><br/>
        This Action is irreversible<br/>
        <button onClick={()=>{dispatch(remove(props.id));props.closeModal()()}}><h5 style={{margin:0}}>Remove Video</h5></button>
      </List> 
    </div>
  )
}
export const CuratorDashboard=(props) => {
  //this shit creates the columns to use in the table function for the component
  const renderRowSubComponent = React.useCallback(
    (row) => (
      <React.Fragment>
        <Modal button='Edit'><EditVideo id={row.values.id}/></Modal>
        <Modal buttonStyle={{marginTop:'0.5rem'}} button='Delete'><RemoveVideo id={row.values.id}/></Modal>
      </React.Fragment>
    ),
    []
  )
  const columns=[
      {
        // Make an expander cell
        Header: 'Actions', // No header
        Cell: ({row}) => {
          return (
            <React.Fragment>
              {renderRowSubComponent(row)}
            </React.Fragment>
          )
        },
      },
      {
        Header: 'Youtube',
        columns: [
          {
            Header: 'Title',
            accessor: 'title',
          },
          {
            Header: 'Description',
            accessor: 'description',
          },
          {
            Header: 'Link',
            accessor: 'link',
          },
          {
            Header: 'YT Views',
            accessor: 'views',
          },
          {
            Header: 'YT Channel',
            accessor: 'channel',
          },
          {
            Header: 'YT Subscribers',
            accessor: 'subs',
          },
          {
            Header: 'YT Upload Date',
            accessor: 'uploadDate',
          }
        ]
      },
      {
        Header: 'Channels',
        columns: [
          {
            Header: 'NowUs Channel 1',
            accessor: 'channels[0]',
          },
          {
            Header: 'NowUs Channel 2',
            accessor: 'channels[1]',
          },
          {
            Header: 'NowUs Channel 3',
            accessor: 'channels[2]',
          }
        ]
      },
      {
        Header: 'Broadcast Days',
        columns: [
          {Header: 'Monday',accessor: 'broadcastDays[0]'},
          {Header: 'Tuesday',accessor: 'broadcastDays[1]'},
          {Header: 'Wednesday',accessor: 'broadcastDays[2]'},
          {Header: 'Thursday',accessor: 'broadcastDays[3]'},
          {Header: 'Friday',accessor: 'broadcastDays[4]'},
          {Header: 'Saturday',accessor: 'broadcastDays[5]'},
          {Header: 'Sunday',accessor: 'broadcastDays[6]'}
        ],
      },
      {
        Header: 'Broadcast Time',
        columns: [
          {Header: 'Am',accessor: 'broadcastTimeframes[0]'},
          {Header: 'Pm',accessor: 'broadcastTimeframes[1]'}
        ],
      },
      {
        Header: 'Broadcast Date Timeframe',
        columns: [
          {
            Header: 'Broadcast Start Date',
            accessor: 'startDate',
          },
          {
            Header: 'Broadcast End Date',
            accessor: 'endDate',
          },
        ]
      },
      {
        Header: 'Unique Video Key',
        columns:[
          {Header:'Id',accessor:'id'}
        ]
      }
    ];
  const dispatch=useDispatch();
  const profile=useSelector(state => state.firebase.profile);
  const uid = useSelector(state => state.firebase.auth.uid)
  useFirestoreConnect('videos');
  const [selectedChannel,setSelectedChannel]=React.useState(0);
  const rawVideos=useSelector(({firestore: {data}}) => data.videos)
  let videosValues=rawVideos?Object.values(rawVideos):[];
  let videoKeys=rawVideos?Object.keys(rawVideos):[];
  let videos=videosValues.map((video,i)=>{return {...video,id:videoKeys[i]}}).filter(video=>{if(profile.type=='admin'){return true}return video.authorId==uid})

  const data=[
    ...videos.filter(video=>video.channels&&video.channels.includes(channels[selectedChannel]))
  ];

  return (
    <SCuratorDash>
      {!profile?(<Redirect to='/' />):null}
      <Grid c='4' style={{height: '100vh',overflow: 'hidden'}}>
        <GridItem className='sidebar'>
          <Grid r='4' style={{height: '100vh'}}>
            <AccountCard/>
            <GridItem rs='3'>
              <ul>
                {channels.map((channel,i) => {
                  return (
                    <ChannelLi
                      channel={channel}
                      selectedIndex={selectedChannel}
                      index={i}
                      onClick={() => {setSelectedChannel(i);}}
                    />
                  );
                })}
              </ul>
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem cs='3' style={{height: '100vh',overflow: 'auto',}}>
          <section style={{margin: '1rem 0'}}>
            <Modal buttonStyle={{marginRight:'1rem'}} button={(() => {return (<List style={{minWidth: '10rem'}} flex={{align: 'center',justify: 'space-between'}}><FaPlusSquare /> Add Video</List>);})()}>
              <NewVideoPanel />
            </Modal>
            {(profile.type==='admin'&&(
              <Modal button={(() => {return (<List style={{minWidth: '10rem'}} flex={{align: 'center',justify: 'space-between'}}><FaSearch/> Search User</List>);})()}>
                <div style={{padding:'1rem',borderRadius:'1rem',height:"100%", boxSizing:'border-box',backgroundColor:style.constants.colors.white}}>
                </div>
              </Modal>
            ))}
          </section>
          <Table columns={columns} data={data} />
        </GridItem>
      </Grid>
    </SCuratorDash>
  );
};

export default CuratorDashboard;