import React from "react";
//Pages
import Page from "./page";
import PageSection from "../components/pageSection";
import Header from "../components/header";
import { defaultHeader } from "../config/pages";
import "./login.css";
const Faq = (props) => {
  return (
    <Page defaultHeader defaultFooter title="NowUs - Frequent Watcher Program">
      <Header {...defaultHeader} />
      <PageSection>
        <div className="container1 hight-auto">
          <div
            class="faq"
            style={{
              padding: "40px 0",
              background: `url(
                    "https://www.nowus.com/static/media/FAQBackground.b41ddc01.svg"
                )`,
              height: "142vh",
              backgroundSize: "100%",
            }}
          >
            <div class="container">
              <h3 style={{ marginTop: "75px" }}>
                What is the NowUs Frequent Watcher program?
              </h3>
              <p>
                NowUs Frequent Watcher Program is a reward system for members,
                providing them with reward points that can later be redeemed for
                lots of fun things to brighten their life at no or little cost.
              </p>
              <div class="accordion" id="accordionExample">
                <div class="card">
                  <div class="card-header" id="headingTwo">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <h4>ACQUIRING POINTS:</h4>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseTwo"
                    class="collapse"
                    aria-labelledby="headingTwo"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      NowUs Frequent Watcher Points are easy to acquire, the
                      more you watch entire shows on NowUs, the more NowUs
                      Frequent Watcher Points you acquire OR you can refer NowUs
                      people you know that are 50plus and when they become a
                      NowUs member, you get more NowUs Frequent Watcher Points
                      too.
                    </div>
                  </div>
                </div>
                <div class="card">
                  <div class="card-header" id="headingThree">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left collapsed"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <h4> POINT SYSTEM:</h4>
                      </button>
                    </h2>
                  </div>
                  <div
                    id="collapseThree"
                    class="collapse"
                    aria-labelledby="headingThree"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      Here&#39;s the point system. 20 points = watching one
                      entire show on NowUs. 100 points = getting anyone to
                      become a member of NowUs.
                    </div>
                  </div>
                </div>

                <div class="card">
                  <div class="card-header" id="headingOne">
                    <h2 class="mb-0">
                      <button
                        class="btn btn-link btn-block text-left"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <h4>REDEEMING POINTS:</h4>
                      </button>
                    </h2>
                  </div>

                  <div
                    id="collapseOne"
                    class="collapse show"
                    aria-labelledby="headingOne"
                    data-parent="#accordionExample"
                  >
                    <div class="card-body">
                      With your NowUs Frequent Watcher Points, you can redeem
                      them for special rewards like free or discounted
                      merchandise, special coupons, unique discounts or other
                      neat things at no or little cost
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageSection>
    </Page>
  );
};

export default Faq;
