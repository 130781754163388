import React from 'react';
import Page from './page';
import PageSection from '../components/pageSection'
import {Redirect} from 'react-router-dom';
import NowUsPlayer from '../components/nowusPlayer';
import style from '../styles';
import Header from '../components/header';
import logo from '../resources/NowUsCircleLogo.svg';
import { connect } from 'react-redux';

const Watch=connect(
  state=>{
    return {id:state.currentUser.user}
  }
  )(props=> {
  // const pageRef=React.createRef();
    console.log(props);
  return (
    <React.Fragment>
      {!props.id&&(<Redirect to='/login'/>)}
      
      <Page title='Watch'>
        <Header mobile={false} style={{color:style.constants.colors.white,backgroundColor:'transparent'}} logo={logo} watchPage={props.match}/>
        <PageSection style={{backgroundColor:style.constants.colors.black}}>
          <NowUsPlayer auth={props.id}/>
        </PageSection>
      </Page>
    </React.Fragment>
  );
});

export default Watch;
