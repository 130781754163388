import React from 'react';
import Page from './page';
import style from '../styles';
//Component
import IntroF from '../components/fullscreen/introF';
import DividerF from '../components/fullscreen/dividerF';
import Card from '../components/card';
import Grid from '../components/grid';

//Image
import DividerBackground from '../resources/dividerbackground.png';
import backgroundIMG from '../resources/FAQBackground.svg';
import CleaningLadyImg from '../resources/cleaninglady.jpg';
import MotherConfettiImg from '../resources/motherconfetti.jpg';
import WhisperingSecretImg from '../resources/whisperingsecret.jpg';
import CheerfulFamilyImg from '../resources/iStock-1226233033.jpg';
import FaceMontageImg from '../resources/iStock-1213470229.jpg';
import FamilyWatchingTVImg from '../resources/familywatchingtv.jpg';
import JesusImg from '../resources/jesus.jpg';
import TabletSeniorImg from '../resources/seniormanreadingtablet.jpg';
import ClownImg from '../resources/rudeofficeclown.jpg';
import SuperheroesImg from '../resources/superheroes.jpg';
import FreelancerImg from '../resources/freelancer.jpg';
import TabletSeniorLaughImg from '../resources/seniorusingtablelaughing.jpg';
import DrinkingWineImg from '../resources/Createwithus.png';

const Branding=() => {
  return (
    <Page defaultHeader defaultFooter title="Branding">
      <IntroF
        img={CleaningLadyImg}
        bgColor={style.constants.colors.primary}
        sidebarColor={style.constants.colors.secondary}
        style={{paddingTop: '100px'}}
      >
        <h3 style={{color: 'transparent','-webkit-text-stroke': '3px black'}}>YOUR BRAND<br /> OUR INFLUENCERS!</h3>
      </IntroF>
      <DividerF
        height='5rem'
        img={DividerBackground}
        color={style.constants.colors.white}
      />
      <IntroF style={{color: 'transparent'}}
        img={MotherConfettiImg}
      >
        <h4 style={{'-webkit-text-stroke': '3px white'}}>SOCIAL-FIRST <br /> ENTERTAINMENT <br /> WITH Now<strong style={{color: 'white'}}>Us</strong></h4>
        <p style={{'color': 'white'}}>
          Today's most valuable audiences create their own programming. 50plussers hate interruptions, and can spot a sales pitch a mile away.
          <br /><br /> 
          Their friends are entertainment – and they want entertainment to behave like a friend. These consumers aren't viewers. 
          <br /> They're connectors – building communities around their shared passion for people, places, and programs. 
          <br/><br/>
          It's more than engagement. It's SIMPLE PLEASURE.  
          <br />And it's the focus and the fuel that powers today's best brand marketing for 50plussers.
          <br /><br/>
          <b>
          For more information about how our creator influencers can </b><br /> <b> assist your brand, email us INFO@NowUs.com </b>
        </p>
      </IntroF>

      <DividerF
        height='5rem'
        img={DividerBackground}
      />
      <div style={{'background-image': `url(${backgroundIMG})`,backgroundRepeat: 'no-repeat','background-size': 'cover'}}>
        <h1 style={{whiteSpace:'nowrap',color: 'transparent','-webkit-text-stroke': '3px black',transform: 'translate(-45%, 300%) rotate(-90deg)',position:'absolute', bottom:'-100%'}}>OUR SOLUTIONS</h1>
        <Grid gap='4.5rem' style={{height: 'fit-content',width: '100%',padding:'5rem 0'}}>
          <Card style={{overflow: 'hidden'}}
            title='SOCIAL MARKETING'
            content='Social Strategy Content & Creative Community Management'
            img={WhisperingSecretImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='STRATEGY & INSIGHTS'
            content='Audience Insights Brand & Business Strategy Measurement & Analytics'
            textalign='left'
            img={CheerfulFamilyImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='VIDEO OPTIMIZATION'
            content='Social Video Strategy Channel Management Organic Distribution'
            img={FaceMontageImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='NowUs TV is LIVE'
            content="All our brand's influencer's videos are highly featured"
            img={FamilyWatchingTVImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='PAID MEDIA'
            content='Cross-Platform Planning & Buying YouTube Media Paid Social'
            img={JesusImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='INSIGHTS'
            content='We are passionate, data-driven experts in the 50+ culture and audiences – and the social platforms that they can’t live without.'
            img={TabletSeniorImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='STRATEGY'
            content='We consider your audience, your brand, and your business context to develop strategies that drive differentiation, brand value, and business impact.'
            img={ClownImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='CREATIVITY'
            content='We create engaging, entertaining programs that connect with audiences authentically and efficiently – on the platforms and channels where they live.'
            img={SuperheroesImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='PRODUCTION'
            content='Our in-house production capabilities were born in Hollywood but bred for digital; we make everything from gifs to episodic series, at internet speed and scale.'
            img={FreelancerImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='IMPACT'
            content='We relentlessly measure, test, and optimize to deliver meaningful results – and then some.'
            img={TabletSeniorLaughImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='DISTRIBUTION'
            content='We combine organic distribution and paid media to connect craveable content with the audiences and communities who will love it most.'
            img={DrinkingWineImg}
          />
        </Grid>
      </div>
      
    </Page>
  );
};

export default Branding;