import React, { useState } from "react";

//Page Things
import Page from "./page";
import PageSection from "../components/pageSection";
import { Redirect, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "../redux/user/user.actions";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Redux

//Components
import CardGrid from "../components/cardGrid";
import NamedInput from "../components/namedInput";
import Button from "../components/button";
import Cs from "../components/colorSelect";
import { GridItem } from "../components/grid";
// import ReactPixel from '../config/facebookpixel';

//Images
import LoginIMG from "../resources/LoginIMG.png";
import { connect } from "react-redux";
// import { useHistory } from "react-router-dom";

// import {
//   Link
// } from "react-router-dom";

const mapStateToProps = (state) => {
  return {
    id: state.currentUser.user,
    error: state.currentUser,
  };
};

const Register = connect(mapStateToProps)((props) => {
  const [stateError, setStateError] = React.useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const emailRef = React.createRef();
  const firstNameRef = React.createRef();
  const lastNameRef = React.createRef();
  const addressRef = React.createRef();
  const cityRef = React.createRef();
  const stateRef = React.createRef();
  const zipcodeRef = React.createRef();
  const genderRef = React.createRef();
  const passwordRef = React.createRef();
  const confirmPasswordRef = React.createRef();
  const dispatch = useDispatch();
  const history = useHistory();
  // const currentUser = useSelector(state => state.currentUser)

  const handleDateChange = (date) => {
    setStartDate(date);
  };

  const handleSubmit = () => {
    let firstName = firstNameRef.current.value;
    if (!firstName) {
      return setStateError({ message: "Missing First Name" });
    }
    let lastName = lastNameRef.current.value;
    if (!lastName) {
      return setStateError({ message: "Missing Last Name" });
    }
    let address = addressRef.current.value;
    if (!address) {
      return setStateError({ message: "Missing Address" });
    }
    let city = cityRef.current.value;
    if (!city) {
      return setStateError({ message: "Missing City" });
    }
    let state = stateRef.current.value;
    if (!state) {
      return setStateError({ message: "Missing State" });
    }
    let zipcode = zipcodeRef.current.value;
    if (!zipcode) {
      return setStateError({ message: "Missing Zipcode" });
    }
    let gender = genderRef.current.value;
    if (!gender) {
      return setStateError({ message: "Missing Gender" });
    }
    let email = emailRef.current.value;
    if (!email) {
      return setStateError({ message: "Missing Email" });
    }
    const password = passwordRef.current.value;
    if (!password) {
      return setStateError({ message: "Missing Password" });
    }
    const c_password = confirmPasswordRef.current.value;
    if (!c_password) {
      return setStateError({ message: "Missing Confirm Password" });
    }

    if (password !== c_password) {
      return setStateError({ message: "Passwords Do Not Match" });
    }
    if (
      !password.match(
        new RegExp(
          "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
        )
      )
    ) {
      return setStateError({ message: "Password Does Not Match Pattern" });
    }
    email = email.toLowerCase();
    firstName = firstName.toLowerCase();
    lastName = lastName.toLowerCase();
    state = state.toLowerCase();
    city = city.toLowerCase();
    address = address.toLowerCase();
    gender = gender.toLowerCase();

    dispatch(
      register(
        firstName,
        lastName,
        email,
        address,
        city,
        state,
        zipcode,
        gender,
        startDate,
        password,
        c_password
      )
    )
      .then((res) => {
        console.log(res);
        history.push("/watch");
      })
      .catch((err) => {
        setStateError({ message: "Email already Exists !" });
      });
    // dispatch(register(,{
    //   firstName,
    //   lastName,
    //   address,
    //   email,
    //   city,
    //   state,
    //   zipcode,
    //   birth,
    //   password,
    //   confirmPassword
    // }),(noError)=>{
    //   if(!noError){useHistory.push('/watch')}
    // });
  };
  let error = null;
  if (stateError) {
    error = stateError;
  }
  // const error=props.error||stateError;
  // console.log(error);
  return (
    <React.Fragment>
      {props.id && <Redirect to="/watch" />}
      <Page
        defaultFooter
        defaultHeader={{ relative: true }}
        title="NowUs - SignUp"
      >
        <PageSection
          bgImg={LoginIMG}
          style={{ padding: "3rem", height: "fit-content", minHeight: "80vh" }}
          flex={{ align: "stretch" }}
        >
          <h4 style={{ color: "black" }}>
            STEP 1: <br />
            <h5>
              As part of becoming a viewing member of NowUs, register your
              information to create your account.
            </h5>
          </h4>
          <GridItem
            style={{
              background:
                "linear-gradient(180deg, rgba(157, 232, 251, 0.6) 0%, rgba(157, 232, 251, 0.6) 100%)",
              padding: "2rem",
              borderRadius: "20px",
            }}
          >
            <CardGrid gap="1rem">
              <NamedInput required ref={firstNameRef} r name="First Name" />
              <NamedInput required ref={lastNameRef} r name="Last Name" />
              <NamedInput required ref={addressRef} name="Address" />
              <NamedInput required ref={cityRef} name="City" />
              <NamedInput required ref={stateRef} name="State" />
              <NamedInput required ref={zipcodeRef} r name="Zipcode" />
              <NamedInput required ref={genderRef} r name="Gender" />

              <div class="sc-dmRaPn idVuTx">
                <h6>Date Of Birth*</h6>
                <DatePicker
                  selected={startDate}
                  onChange={handleDateChange}
                  required
                  name="Date Of Birth"
                  dateFormat="MM/dd/yyyy"
                />
              </div>

              {/* <NamedInput required ref={birthRef} r name='Date Of Birth' dateFormat="MM/dd/yyyy"  /> */}

              <NamedInput
                required
                ref={emailRef}
                r
                name="Email"
                style={{ gridColumn: "span 2" }}
              />
              <NamedInput
                required
                ref={passwordRef}
                style={{ gridColumn: "span 2" }}
                r
                name='Password - (Requires at least one upper case letter and one special character - "@#$%^")'
                type="password"
              />
              <NamedInput
                required
                ref={confirmPasswordRef}
                style={{ gridColumn: "span 2" }}
                r
                name="Confirm Password - (Must match password typed above exactly)"
                type="password"
              />
            </CardGrid>
            {error && (
              <h6 style={{ marginBottom: ".5rem" }}>
                <Cs color="red">{error.message}</Cs>
              </h6>
            )}
          </GridItem>
          <Button
            onClick={handleSubmit}
            style={{ width: "100%", margin: "auto", marginTop: "1%" }}
          >
            {/* <Link to='/watch'> */}
            <p>Login </p>

            {/* </Link> */}
          </Button>
        </PageSection>
      </Page>
    </React.Fragment>
  );
});

export default Register;
