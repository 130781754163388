import React from 'react';
import styled from 'styled-components';
import style from '../styles';
import {rgba} from 'polished';
import {FaTimesCircle} from 'react-icons/fa';

const SModal=styled.div`
  position:fixed;
  display:none;
  width: 100%;
  height:100%;
  top:0;
  bottom:0;
  left:0;
  right:0;
  z-index:300;
  figure{
    width: 100vw;
    height: 100vh;
    background-color:${rgba(style.constants.colors.black,0.2)};
    opacity:0.2
  }
  &.-open{
    display:block;
  }
  .modalContent{
    background-color:white;
    height:70%;
    width:70%;
    position:fixed;
    top:15%;
    left:15%;
    z-index:301;
  }
`;
const Modal=props => {
  const [open,setOpen]=React.useState(false);
  let elements=React.Children.toArray(props.children);
  elements=elements.map(element=>React.cloneElement(element,{closeModal:(fn)=>()=>{setOpen(!open);return fn&&fn()},open}));
  return (
    <React.Fragment>
      <button style={{...props.buttonStyle}} className='onButton' onClick={() => {setOpen(!open);}}>{props.button}</button>
      <SModal className={open? '-open':''} style={{...props.containerStyle}}>
        <figure onClick={() => {setOpen(!open);}} />
        <div style={props.style} className='modalContent'>
          {elements}
          <FaTimesCircle onClick={() => {setOpen(!open);}} style={{position: 'absolute',top: '0.5rem',right: '1rem'}} />
        </div>
      </SModal>
    </React.Fragment>
  );
};

export default Modal;