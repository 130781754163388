import React from 'react';
import Page from './page';
import style from '../styles';

//Component
import IntroF from '../components/fullscreen/introF';
import DividerF from '../components/fullscreen/dividerF';
import Card from '../components/card';
import Grid from '../components/grid';

//Image
import DividerBackground from '../resources/dividerbackground.png';
import GrandmotherSelfieImg from '../resources/grandmotherselfie.jpg';
import EricTheCarGuy from '../resources/ericthecarguy.jpg';
import CollageImg from '../resources/collage.jpg';
import DevicesfromaboveImg from '../resources/tech.jpg';
import backgroundIMG from '../resources/FAQBackground.svg';
import ElderlyPaintImg from '../resources/happinessofseniorman.jpg';
import EccentricManImg from '../resources/iStock-1167047685.jpg';
import SparklerAtNightImg from '../resources/sparkleratnight.jpg';
import CelebratingImg from '../resources/celebrating.jpg';
import OldManHelmentImg from '../resources/oldmanhelmet.jpg';
import QuirkyRestaurantImg from '../resources/quirkyrestaurant.jpg';
import LongboardImg from '../resources/longboard.jpg';
import CrazySeniorImg from '../resources/crazysenior.jpg';
import GrandpaBorgerImg from '../resources/grandpaborger.jpg';


const Creators=() => {
  return (
    <Page defaultHeader defaultFooter title="Creators">
      <IntroF
        style={{paddingTop: '100px'}}
        img={GrandmotherSelfieImg}
      >
        <h3 style={{color: 'transparent','-webkit-text-stroke': '3px white'}}>CREATE WITH <br /> Now<strong style={{color: 'white'}}>Us</strong></h3>
        <p style={{color: 'white'}}>WE HELP CREATORS/INFLUENCERS <br /> REACH THEIR FULL POTENTIAL<br /><br /><h6 style={{marginTop: '0.5rem',color: 'white'}}>{"( Scroll Down For More Info )"}</h6>
        </p>
      </IntroF>
      <DividerF
        height='8rem'
        img={DividerBackground}
        color={style.constants.colors.white}
      >
        <h2 style={{height: '100%',color: style.constants.colors.white,textAlign: 'center'}}>TALENT MANAGEMENT</h2>
      </DividerF>
      <IntroF
        side='right'
        bgColor={style.constants.colors.primary}
        sidebarColor={style.constants.colors.secondary}
        img={CollageImg}
      >
        <h3 style={{color: 'transparent','-webkit-text-stroke': '3px black'}}>WHY CHOOSE <br /> Now<strong style={{color: 'black'}}>Us</strong>?</h3>
        <p style={{color: 'black',width: 'fit-content'}}>
          As the entertainment industry changes,
          <br /> so too does the basic core of celebrity. In this era of social media, user-generated content,
          <br />cable and broadcast television,film and the mass accessibility of information;
          <br />power has shifted away from the actor of old and into the hands of talent who are famous for being themselves.
          <br /><br />Branded personalities, lifestyle experts have the necessary authenticity and credibility with both the general
          <br /> public and brands to effectively communicate messages and generate profits.
          <br /> <br /> NowUs harnesses this  power for the benefit of our clients and the various stakeholders with whom they do business.
          <br /><br />
          <b>
          For more information about how we help creators/influencers, </b><br /><b>email us INFO@NowUs.com</b>
        </p>
      </IntroF>
      <DividerF
        height='5rem'
        color={style.constants.colors.dblue}
      />
      <IntroF
        img={DevicesfromaboveImg}
      >
        <h4 style={{color: 'transparent','-webkit-text-stroke': '3px white'}}>REALIZE YOUR <br /> TOTAL POTENTIAL <br /> ON EVERY <br /> PLATFORM</h4>
        <p style={{color: 'white'}}>In addition to managing your brand, <br />
        we also assist you online.<br />
        Whether creating content is your passion, profession, <br /> or your way to stardom, we have services and solutions <br /> crafted to help you succeed across the world's most <br /> popular platforms and beyond.</p>
      </IntroF>

      <h4 style={{'text-align': 'center'}}>OUR CLIENTS ARE OUR INFLUENCER PARTNERS<br />THAT'S WHY WE <u>CHOOSE</u> THEM WITH CARE</h4>

      <DividerF
        height='5rem'
        img={DividerBackground}
      />
      <div style={{'background-image': `url(${backgroundIMG})`,backgroundRepeat: 'no-repeat','background-size': 'cover'}}>
        <h1 style={{whiteSpace: 'nowrap',color: 'transparent','-webkit-text-stroke': '3px black',transform: 'translate(-45%, 300%) rotate(-90deg)',position: 'absolute'}}>OUR SERVICES</h1>
        <Grid gap='4.5rem' style={{height: 'fit-content',width: '100%',padding: '5rem 0'}}>
          <Card style={{overflow: 'hidden'}}
            title='MANAGEMENT'
            content='Our experienced talent professionals help you navigate all aspects of your brand and career.'
            textalign='right'

            img={ElderlyPaintImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='MULTI-PLATFORM
            MONETIZATION'
            content='Don’t limit yourself to just YouTube. We’ll help drive revenue across all of your content and beyond.'
            textalign='left'
            img={EccentricManImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='DIRECT TO FAN'
            content='Manage and monetize your fan-base better than ever with our NowUs Direct platform.'
            textalign='right'
            img={SparklerAtNightImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='LIVE EVENTS'
            content='Connect with your closest fans on national tours and hello! meet & greets.'
            textalign='left'
            img={CelebratingImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='MERCHANDISING'
            content='Extend your brand with our product licensing and e-commerce solutions.'
            textalign='right'
            img={EricTheCarGuy}
          />
          <Card style={{overflow: 'hidden'}}
            title='RIGHTS 
            MANAGEMENT'
            content='Monetize your content re-uploaded by other users.'
            textalign='left'
            img={OldManHelmentImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='CASTING AND
            DEVELOPMENT'
            content='From hosting and casting opportunities to packaging original series and films, we help you navigate Hollywood.'
            textalign='right'
            img={QuirkyRestaurantImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='BRAND 
            PARTNERSHIPS'
            content='We match you with world-class brands, boosting your exposure and revenue on every platform.'
            textalign='left'
            img={LongboardImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='CREATOR
            PLATFORM'
            content='Our expert team works tirelessly to secure targeted deals to fit your content AND to evolve your content into a multi-layered business.'
            textalign='right'
            img={CrazySeniorImg}
          />
          <Card style={{overflow: 'hidden'}}
            title='BRAND 
          OPPORTUNITIES'
            content='Our expert team works tirelessly to secure targeted deals to fit your content AND to evolve your content into a multi-layered business.'
            textalign='left'
            img={GrandpaBorgerImg}
          />
        </Grid>
      </div>

    </Page>
  );
};

export default Creators;