import { combineReducers} from 'redux'
import currentUser from './user/user.reducer';


const rootReducers = combineReducers({
    currentUser,
});


export default rootReducers;




