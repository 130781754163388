import React from 'react';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import style from '../styles';


const SAccordianCollapse=styled.div`
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 2px;
  margin:5px;
  background-color:${style.constants.colors.white};
  border-style: solid;
  border-width: 1px;
  border-color:${style.constants.colors.primary};
  transition:1s ease;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &:hover{
    background-color: rgba(0, 0, 0, 0.05)
  }
  .Collapsible{
    padding:0.5rem;
    box-sizing: border-box;
    border-radius:20px;
    transition:1s ease;
    &:hover{
      cursor:pointer;
    }
  }
  .Collapsible__contentOuter{
    cursor:default;
  }
  .Collapsible__trigger {
    transition-delay:400ms;
    margin:0;
    font-style:italic;
    &.is-open{
      transition-delay:0s;
      margin-bottom:1rem;
    }
  }
`;

const AccordianCollape=(props) => {
  return (
    <SAccordianCollapse>
      <Collapsible triggerTagName='h5' trigger={props.title}>
        {props.content&&(<p style={{fontStyle: 'italic'}}> {props.content}</p>)}
        {props.children}
      </Collapsible>
    </SAccordianCollapse>
  );
};

export default AccordianCollape;