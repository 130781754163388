import React from 'react';
import style from '../../styles';
import styled from 'styled-components';
import {rgba} from 'polished';

const SIntroF=styled.section`
  height:${props => props.height||'40rem'};
  ${props=>style.mixins.responsiveValue('height',[props.height?props.height.replace('rem',''):'45','rem'],[0.6,1.3,1.35])}
  position:relative;
  min-height:fit-content;
  ::before{
    position:absolute;
    ${style.mixins.media(style.mixins.mediaSmall,`
      width:100% !important;
      background-color:${rgba(style.constants.colors.black,0.6)};
      color:${style.constants.colors.white} !important;
    `)}
    z-index:3;
    top:0;
    bottom:0;
    ${props => props.sidebar.position}:0;
    content:'';
    ${style.mixins.responsiveValue('width',['33','rem'],[0.7,1.3,1.4])}
    ${props => props.sidebar.width?`width:${props.sidebar.width};`:''}
    max-width:100%;
    background:${props => props.sidebar.color};
  }
  article{
    ${props => props.sidebar.position}:8vw;
    text-align:${props => props.sidebar.position};
    top:${props => props.sidebar.top||'25%'};
    position:absolute;
    left: ${props => props.sidebar.left};
    right: ${props => props.sidebar.right};
    z-index:30;
    ::before{
      max-width:100%;
      position:absolute;
      top:0;
      bottom:0;
      ${props => props.sidebar.position}:-2.5vw;
      content:'';
      width:1vw;
      height:100%;
      background-color:${props => props.sidebar.beforeColor};
    }
  }
  figure{
    background-image:url(${props => props.img});
    width:100%;
    height:100%;
    ${style.mixins.background()}
    overflow:hidden;
    position:absolute;
    img{
      width:auto;
      height:100%;
    }
  }
`;

  const IntroF=(props) => {
  return (
    <SIntroF {...props} style={{...props.style}}>
      <article style={{...props.contentStyle}}>{props.sidebar.content}</article>
    </SIntroF>
  );
};

const SImageNSection=styled.section `
  position:relative;
  height:fit-content;
  box-sizing:border-box;
  display:flex;
  ${style.mixins.media(style.mixins.mediaSmall,`
    padding-top:0 !important;
  `)}
  background-color:${props=>props.bgColor?props.bgColor:style.constants.colors.secondary};
  article,figure{
    ${style.mixins.media(style.mixins.mediaSmall,`
      width:100%;
    `)}
  }
  article{
    ${props=>props.side==='right'&&`order:1;`}
    ${props=>props.side==='right'&&`text-align:right;`}
    box-sizing:border-box;
    position:relative;
    z-index:2;
    padding:2rem;

    margin:2rem;
    ${props=>props.side==='right'&&`margin:2rem 2rem 2rem auto;`}
    width:max-content;
    *+*{
      margin:0;
      margin-top:1rem;
    }
    ::before{
      position:absolute;
      top:0;
      ${props=>props.side?props.side:'left'}:0;
      bottom:0;
      margin:auto 0;
      content:'';
      width:1vw;
      ${props=>props.side?props.side:'left'}:-1vw;
      height:96%;
      background-color:${props=>props.sidebarColor?props.sidebarColor:style.constants.colors.primary}
    }
    ${style.mixins.media(style.mixins.mediaSmall,`
      padding:6vw;
      *{
        color:${style.constants.colors.white};
      }
      h4,h3,h1,h2{
        -webkit-text-stroke: 1px ${style.constants.colors.white} !important;
      }
    `)}
  }
  .cover{
    position:absolute;
    top:0;
    left:0;
    bottom:0;
    right:0;
    ${style.mixins.media(style.mixins.mediaSmall,`
      background-color:${rgba(style.constants.colors.black,0.6)};
    `)}
  }
  figure{
    width:100%;
    ${style.mixins.background()}
    ${props=>props.img&&`background-image:url(${props.img});`}
    ${style.mixins.media(style.mixins.mediaSmall,`
      position:absolute;
      top:0;
      bottom:0;
      left:0;
      right:0;
    `)}
  }
`
const ImageNSection=(props)=>{
  return (
    <SImageNSection {...props}>
      <article>
        {props.children}
      </article>
      <figure/>
      <div className='cover'/>
    </SImageNSection>
  )
}

export default ImageNSection;
