import React from 'react';
import Page from './page';
import List from '../components/list';
import PageSection from '../components/pageSection';
import {AccountCard} from './user';
import {connect} from 'react-redux';
import { Redirect } from 'react-router-dom';
import backgroundImage from '../resources/diversity.jpg';


const mapStateToProps=state => {
  return {
    id: state.currentUser.user,
    error:state.currentUser
  };
};

const Account=connect(mapStateToProps)(props=>{
  return (
    <React.Fragment>
      { props.id === null ? <Redirect to="/watch" /> : 
        <Page defaultHeader={{relative: true}} defaultFooter title={`${props.id.firstName}'s Account - NowUs`}>
          <PageSection size='79vh' style={{backgroundImage:`url(${backgroundImage})`}}>
            <List flex={{justify: 'center',align: 'center',direction: 'column'}} style={{height: '100%'}}>
              <AccountCard/>
            </List>
          </PageSection>
        </Page>
      }
    </React.Fragment>
  );
});





export default Account;
