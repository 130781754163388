import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {useDispatch} from 'react-redux';
import types from '../redux/auth/types';
export default function ScrollToTop() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({type:types.RESET_ERROR});
  }, [pathname]);

  return null;
}