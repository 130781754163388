import React from 'react';
import {Link,withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import mixins from '../styles/mixins';
import styled from 'styled-components';
import style from '../styles';
import {FaBars} from 'react-icons/fa';

const SNav=styled.nav`
  z-index:100;
  color:${props => props.color||'inherit'};
  ${props=>props.mobile!==false&&mixins.media(mixins.mediaSmall,`
    #menuButton{
      display:initial !important;
    }
  `)}
  
  #menuButton{
    cursor:pointer;
    display:none;
    fill:${style.constants.colors.secondary};
    width:3rem;
    height:3rem;
    ${props=>{return `
      &.--open{
        position:fixed;
        right:0;
        top:0;
        margin:0.5rem;
        width:1rem;
        height:1rem;
        z-index:1000;
      }
    `}}
  } 
  ul{
    z-index:300;
    ${props=>{
      if(props.mobile!==false){return `
        &.--open{
          display:inline;
        }
        ${mixins.media(mixins.mediaSmall,`
          position:fixed;
          right:0;
          top:0;
          height:100vh;
          display:none;
          background-color:${style.constants.colors.white};
        `)}
      `}
    }}
    ${mixins.flex({align: 'center',justify: 'space-evenly',direction: 'row'})}
    li{
      p{
        font-size: 20px;
        transition: ease-in-out 0.4s;
        border-top: 5px solid transparent;
        :hover{
          border-top: 5px solid ${style.constants.colors.primary};
        }
        padding: 0.1rem;
      }
      padding: 0.5rem;
      ${mixins.media(mixins.mediaSmall,`
        svg{
          margin-right:0;
          width:1.2rem;
          height:1.2rem;
        }                                                                                                                                                                      
      `)}
      a{
        ${mixins.flex({direction: 'row',align: 'center',justify: 'center'})}
        color:inherit;
        svg{
          margin-right:0.5rem
        }
        p{
          letter-spacing:0.06em;
          color:inherit;
          margin:0;
        }
      }
    }
  }
`;

export const NavItem=props => {
  if(!props.title) {return null;}
  return (
    <li>
      {props.download?
        (
          <a style={{textDecoration:'none'}} href={props.href} download>
            {props.icon&&(<props.icon />)}
            <p>{props.title}</p>
          </a>
        )
        :
        (
          <Link to={props.href} style={{textDecoration:'none'}}>
            {props.icon&&(<props.icon />)}
            <p>{props.title}</p>
          </Link>
        )
      }
    </li>
  );
};

const Navbar=(props) => {
  const [menuOpen, setMenuOpen] = React.useState(false)
  return (
    <SNav  {...props} style={{...props.style}}>
      <ul className={menuOpen?'--open':''}>
        {props.items&&props.items.map(item => {
          if(item.filter&&item.filter(props)) {return null;}
          if(item.component) {return (<item.component {...props} {...item} key={item.title} />);}
          return (
            <NavItem {...item} key={item.title} />
          );
        })}

      </ul>
      <FaBars className={menuOpen?'--open':''} onClick={()=>{setMenuOpen(!menuOpen)}} id='menuButton'/>
    </SNav>
  );
};


const mapStateToProps=(state) => {
  return {
    auth: state.currentUser.user,
  };
};
export default withRouter(connect(mapStateToProps)(Navbar));


