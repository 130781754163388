import {REGISTER_USER_SUCCESS,REGISTER_ERROR,LOGIN_SUCCESS,LOGIN_ERROR,LOGOUT_SUCCESS,UPDATE_USER_SUCCESS,UPDATE_USER_BIRTH,USER_DELETE_SUCCESS,USER_DELETE_ERROR} from './user.types';
const user = JSON.parse(localStorage.getItem("user"));

const INITIAL_STATE = user ? {isLoggedIn:true , user:user.data,message:null} : {isLoggedIn:false , user:null,message:null};


const currentUser = (state = INITIAL_STATE ,action)=>{
    const {type,payload} =action;
    console.log(state);
    switch (type){
        case REGISTER_USER_SUCCESS:
            return{
                ...state,
                isLoggedIn:true,
                user : payload,
                message:null
            };
        case REGISTER_ERROR:
            return{
                isLoggedIn:false,
                message:payload.msg,
                ...state,
            };
        case LOGIN_SUCCESS:
            return{
                ...state,
                isLoggedIn:true,
                user : payload,
                message:null
            };
        case LOGIN_ERROR:
            return{
                ...state,
                isLoggedIn:false,
                user: null,
                message:payload.msg
            }
        case LOGOUT_SUCCESS:
            return{
                ...state,
                isLoggedIn:false,
                user : null
                
            }
        case UPDATE_USER_SUCCESS:
            return{
                ...state,
                user:payload.data
            }
        case UPDATE_USER_BIRTH:
            return{
                ...state,
                user:payload.data
            }
        case USER_DELETE_SUCCESS:
            return{
                ...state,
                user:null,
                isLoggedIn:false,
            }
        case USER_DELETE_ERROR:
            return{
                ...state,
            }
        default:
            return state;
    }
} 

export default currentUser;