export const secondary='#001f5f';
export const primary='#9de8fb';
export const white='#FCFCFC';
export const gray='#E4E6E3';
export const black='#11151C';

export const lgreen='#ACFB90';
export const dgreen='#00F5A0';

export const dblue='#00ABF5';

export const orange='#FA842F;';


export default Object.freeze({
  primary,
  secondary,
  white,
  gray,
  black,
  lgreen,
  dgreen,
  dblue,
  orange
});