import React from 'react';
import styled from 'styled-components';
import style from '../styles';

import {ContentGroup} from '../components/contentGroup';

export const SCard=styled.article`
  ${props => props.flex? style.mixins.flex({align: props.flex.align||'center',justify: props.flex.justify||'center',direction: props.flex.direction||'column'}):''}
  background: rgba(239, 239, 239, 0.5);
  box-sizing: border-box;
  margin: auto;
  width:70%;
  height:30rem;
  position: relative;
article{
    text-align: left;
    height:100%;
    border-radius:20px 20px 0 0;
    width:50%;
    position:absolute;
    padding:1.5rem;
    box-sizing:border-box;
    h4{
      color: transparent;
      -webkit-text-stroke: 3px black;
  }
}
  figure{
    background-color:rgba(239, 239, 239, 0.5);
    height:100%;
    width:50%;
    position:relative;
    float: right;
    ${style.mixins.background()}
  }
`;

const Card=(props) => {
  return (
    <SCard {...props} style={{...props.style}}>
      <figure style={{backgroundImage:`url(${props.img})`}}/>
      <article>
        <h4>{props.title}</h4>
        <p>{props.content}</p>
      </article>
      {props.children}
    </SCard>
  );
};

export const SCard2=styled.article`
  ${props => props.flex? style.mixins.flex({align: props.flex.align||'center',justify: props.flex.justify||'center',direction: props.flex.direction||'column'}):''}
  border: solid ${style.constants.colors.primary} 1px;
  border-radius:20px;
  padding:1rem;
  figure{
    height:10rem;
    border-radius:20px 20px 0 0;
  }
`;

export const Card2=(props) => {
  return (
    <SCard2  {...props} style={{...props.style}}>
      {props.img&&(<figure />)}
      <ContentGroup
        subtitle={props.subtitle}
        title={props.title}
        content={props.content}
        content2={props.content}
        content3={props.content}
        alignment='inherit'
      />
      {props.children}
    </SCard2>
  );
};

export const SLoginCard=styled.article`
  ${props => props.flex? style.mixins.flex({align: props.flex.align||'center',justify: props.flex.justify||'center',direction: props.flex.direction||'column'}):''}
  border: solid ${style.constants.colors.primary} 1px;
  border-radius:20px;
  padding:1rem;
  background-color:'black';
  figure{
    height:10rem;
    border-radius:20px 20px 0 0;
  }
`;

export const LoginCard=(props) => {
  return (
    <SLoginCard  {...props} style={{...props.style}}>
      {props.img&&(<figure />)}
      <ContentGroup
        subtitle={props.subtitle}
        title={props.title}
        content={props.content}
        content2={props.content}
        content3={props.content}
        alignment='inherit'
      />
      {props.children}
    </SLoginCard>
  );
};

export default Card;