import React from 'react';
import {Helmet,HelmetProvider} from 'react-helmet-async';
import {ThemeProvider} from 'styled-components';

import Header from '../components/header';
import Footer from '../components/footer';
import {defaultFooter,defaultNavbar,defaultLogo,defaultLogo2} from '../config/pages';
import ScrollToTop from '../components/scrollToTop';
import ReactPixel from '../config/facebookpixel';

// eslint-disable-next-line import/no-anonymous-default-export
export default props => {
  React.useEffect(() => {
    ReactPixel.pageView()
  })
  return (
    <React.Fragment>
      <ScrollToTop />
      <ThemeProvider theme={props.theme||{}}>
        <HelmetProvider>
          <Helmet>
            {props.title&&(<title>{props.title}</title>)}
            {props.description&&(<meta name="description" content={props.description} />)}
            {props.color&&(<meta name="theme-color" content={props.color} />)}
          </Helmet>
          {props.defaultHeader&&(<Header navbar={defaultNavbar} logo={defaultLogo} logo2={defaultLogo2} {...props.defaultHeader} />)}
          {props.children}
          {props.defaultFooter&&(<Footer {...defaultFooter} {...props.defaultFooter} />)}
        </HelmetProvider>
      </ThemeProvider>
    </React.Fragment>
  );
};