import axios from "axios";
import React, { useEffect, useState } from "react";
import ScrollableFeed from "react-scrollable-feed";
import "../pages/home.css";
import "../pages/login.css";
import Pusher from "pusher-js";
import Echo from "laravel-echo";
import { useSelector } from "react-redux";

const LiveChat = ({ channel }) => {
  const [allLiveChats, setAllLiveChats] = useState([]);
  const [message, setMessage] = useState("");
  const [errAllLiveChats, setError] = useState("");

  const auth = useSelector((state) => state.currentUser);
  useEffect(() => {
    axios
      .get("https://admin.nowus.com/api/video/" + channel + "/live-chat")
      .then((res) => {
        console.log(res);
        setAllLiveChats(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setAllLiveChats([]);
        setError(err.response.data.msg);
      });
  }, [channel]);

  useEffect(() => {
    window.Pusher = Pusher;
    window.Echo = new Echo({
      broadcaster: "pusher",
      key: "anyKey",
      cluster: "mt1",
      encrypted: true,
      wsHost: "admin.nowus.com",
      wsPort: 6002,
      wssPort: 6002,
      forceTLS: false,
      disableStats: true,
      authEndpoint: "/broadcasting/auth",
      enabledTransports: ["ws", "wss"],
    });
    window.Echo.channel("live").listen("Chat", (event) => {
      console.log(event);
      console.log("send");

      setAllLiveChats((oldMessages) => [event, ...oldMessages]);
    });
  }, []);

  const submitMessage = async (e) => {
    e.preventDefault();
    let data = {
      channel_id: channel,
      message: message,
      userName: auth.user.firstName,
    };

    try {
      let liveMessage = await axios.post(
        "https://admin.nowus.com/api/live-chat/" + channel + "/store",
        data
      );
      console.log(liveMessage);
      setMessage("");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="live-chat">
      <div className="chat-container">
        <ScrollableFeed>
          {allLiveChats !== null ? (
            allLiveChats.map((chat, index) => (
              <div className="message" key={index}>
                <div
                  style={{
                    color: "crimson",
                    textTransform: "uppercase",
                    fontSize: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {chat.userName}
                </div>
                <p style={{ color: "black" }}>{chat.message}</p>
              </div>
            ))
          ) : (
            <div className="message">
              <p style={{ color: "red" }}>{errAllLiveChats}</p>
            </div>
          )}
        </ScrollableFeed>
      </div>
      <div className="send-message-form" style={{ textAlign: "center" }}>
        <form onSubmit={(e) => submitMessage(e)}>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type here"
          />
        </form>
      </div>
    </div>
  );
};

export default LiveChat;
