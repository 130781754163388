//Basics
import React from "react";
import Page from "./page";
import style from "../styles";

// import Button from "../components/button";
import IntroF from "../components/fullscreen/introF";
import DividerF from "../components/fullscreen/dividerF";

// Header & Footer
import DividerBackground from "../resources/dividerbackground.png";
import whyNowUsImg from "../resources/iStock-1032613564B2.jpg";

//css
import "./home.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Link } from "react-router-dom";

export const Home = (props) => {
  return (
    <div className="HOME">
      <Page defaultHeader defaultFooter title="NowUs - Home">
        <div>
          <Container fluid>
            <Row className="Home1">
              <Col className="home_col_1">
                <h4 style={{ color: "white" }}>
                  WATCH <br />
                </h4>
                <h4 style={{ color: "white" }}>
                  Now
                  <strong style={{ color: "white", marginLeft: "10px" }}>
                    Us
                  </strong>
                </h4>
                <h4 style={{ color: "white" }}>Internet TV</h4>
                <p style={{ color: "white" }}>
                  We find the best TV shows and videos for people 50plus.
                  <br />
                  No more click click, search search the internet for you!
                  <br />
                  <br />
                  <h6>Become a NowUs member FREE.</h6> <br />
                  Then select a channel, sit back, relax and watch. Just like
                  watching TV.
                </p>
                <Link className="button_sign" to="/register">
                  <button className="button_signup" style={{ width: "100%" }}>
                    <h5>
                      SIGN UP FREE
                      <br /> Click Here
                    </h5>
                  </button>
                </Link>
                <h6 style={{ marginTop: "0.5rem", color: "white" }}>
                  {"( Scroll Down For More Info )"}
                </h6>
              </Col>
              <Col className="home_col_2">
                <div className="FREE">100% FREE</div>
                <div className="video">
                  <iframe
                    id="ytplayer"
                    type="text/html"
                    title=""
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/_V_bo5lT28s?rel=0&amp;autoplay=1&mute=1"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  ></iframe>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <DividerF
          height="10rem"
          img={DividerBackground}
          color={style.constants.colors.white}
        />

        <IntroF img={whyNowUsImg} side="right">
          <h4 style={{ color: "white" }}>
            What is
            <br />
            Now<strong style={{ color: "white" }}>Us</strong>?
          </h4>
          <p style={{ color: "white" }}>
            {" "}
            NowUs has more than a dozen user friendly channels of TV Shows and
            videos specifically for people 50plus with new shows weekly.
            <br /> <br />
            Channels themed by subject for relaxed viewing currently are: <br />
            <br />
            <br />
            Animal, Culture, Documentaries, Funny, Good News,
            <br /> <br />
            Funny, Health & Beauty, History, Love, Nostalgia, reality TV,
            <br /> <br />
            Science, Sports, Travel and with more channels coming.
            <br />
            <br />
            NowUs has more than a dozen user friendly channels of curated
            videos/shows with new content weekly.
            <br />
            <br /> Just sign up for FREE and discover to live better.
          </p>
        </IntroF>

        <DividerF height="10rem" img={DividerBackground} />

        <Container fluid style={{ paddingRight: "0px" }}>
          <Row className="Home2">
            <Col className="home_col_3">
              <div className="heading_3">
                TOP 5 REASONS to become a <h4>NowUs</h4> member.
              </div>

              <div className="bheading_3">Easy To Use</div>

              <div className="body_3">
                NowUs Internet TV searches the internet worldwide to find
                unique, interesting and entertaining shows for you to discover
                on our easy to use watch function. Kind of like how we used to
                watch TV.
              </div>

              <div className="bheading_3">
                Discover New People, Places & Things
              </div>

              <div className="body_3">
                NowUs Internet TV is for OUR generations – people over 50 (like
                us). We are all about finding shows that affect you, enrich your
                life to help you feel young again discovering things that you
                still want to do – or have already done and want to relive and
                recapture that feeling.
              </div>

              <div className="bheading_3">Inclusive</div>

              <div className="body_3">
                NowUs has a huge variety of shows for everyone 50plus with over
                a dozen themed by subject based channels that makes watching
                internet TV just like watching regular TV – but better, because
                it’s for us!
              </div>

              <div className="bheading_3">Safe & Secure</div>

              <div className="body_3">
                NowUs Internet TV is safe, secure, and private. We will not sell
                or share your information and the information we ask for to
                become a FREE member is very basic information to protect your
                info.
              </div>

              <div className="bheading_3">
                As if those reasons aren’t enough, here’s one more: NowUs
                Internet TV memberships are a BARGAIN... it’s 100% FREE!
              </div>

              <div className="body_3">
                NowUs delivers directly to whatever device you want to watch on,
                computer, tablet or Smart TV.
              </div>
              <button
                to="#"
                className="button_signup"
                style={{ width: "100%" }}
              >
                <h5>
                  <Link to="/register">
                    SIGN UP FREE
                    <br /> Click Here
                  </Link>
                </h5>
              </button>
              <h6 style={{ marginTop: "0.5rem", color: "white" }}>
                {"( Scroll Down For More Info )"}
              </h6>
            </Col>
            <Col className="home_col_9">
              <div className="FREE"></div>
              <div className="video"></div>
            </Col>
          </Row>
        </Container>
        {/*color: 'transparent','-webkit-text-stroke': '3px white'*/}
      </Page>
    </div>
  );
};

export default Home;
