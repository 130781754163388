import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

//Pages
import Home from "./pages/home";
import Login from "./pages/login";
import Faq from "./pages/faq";
import TermsOfUse from "./pages/termsofuse";
import Privacy from "./pages/privacy";
import Branding from "./pages/branding";
import Register from "./pages/register";
import Watch from "./pages/watch";
import Account from "./pages/account";
import Creators from "./pages/creators";
import Contact from "./pages/contact";
import Membership from "./pages/membership";
import Video from "./pages/video";
import User from "./pages/user";
import RecoverPassword from "./pages/recoverpassword";
import CuratorDash from "./pages/curator";
import Advertise from "./pages/advertise";
import ForgotPassword from "./pages/forgotPassword";
import Fwp from "./pages/fwp";
import Reward from "./pages/reward";

// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="membership" component={Membership} />
        <Route path="/faq" component={Faq} />
        <Route path="/login" component={Login} />
        <Route path="/tou" component={TermsOfUse} />
        {/* <Route path="/reward" component={Reward} /> */}
        <Route path="/fwp" component={Fwp} />
        <Route path="/privacy" component={Privacy} />
        <Route path="/branding" component={Branding} />
        <Route path="/register" component={Register} />
        <Route path="/account" component={Account} />
        <Route path="/creators" component={Creators} />
        <Route path="/contact" component={Contact} />
        <Route path="/watch" component={Watch} />
        <Route path="/vedio" component={Video} />
        <Route path="/user/:id" component={User} />
        <Route path="/user" component={User} />
        <Route path="/recoverpassword" component={RecoverPassword} />
        <Route path="/advertise" component={Advertise} />
        <Route path="/token/:id" exact component={ForgotPassword} />
        <Route path="/curatordashboard" component={CuratorDash} />
        <Route path="/" component={Home} />
      </Switch>
    </BrowserRouter>
  );
};
