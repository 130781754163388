import axios from "axios";


const API_URL = "https://admin.nowus.com";

const userRegister = (firstName , lastName , email , address , city , state , zipcode, gender , birth , password,c_password) =>{
    return axios.post(API_URL+"/api/register",{
        firstName,
        lastName,
        address,
        city,
        state,
        zipcode,
        birth,
        email,
        gender,
        password,
        c_password
    }).then((response)=>{
        if(response.data.data.token){
            localStorage.setItem('user',JSON.stringify(response.data));
        }
        return response.data;
    })
}

const userLogin = (email,password) =>{
    return axios.post(API_URL+"/api/login",{
        email,
        password
    }).then((response)=>{
        if(response.data.data.token){
            localStorage.setItem('user',JSON.stringify(response.data));
        }
        return response.data;
    })
}

const updateUser = (data) =>{
    const {id,firstName,lastName}= data;
    return axios.post(API_URL+"/api/update-user/"+id,{
        firstName,
        lastName
    }).then((response)=>{
        if(response.data.data){
            localStorage.setItem('user',JSON.stringify(response.data));
        }
        console.log(response.data);
        return response.data;
    })
}

const updateBirth = (data) =>{
    const {id,birth}= data;
    return axios.post(API_URL+"/api/update-birth/"+id,{
        birth,
    }).then((response)=>{
        if(response.data.data){
            localStorage.setItem('user',JSON.stringify(response.data));
        }
        console.log(response.data);
        return response.data;
    })
}


const saveTokenInLocalStorage = (token) =>{
    localStorage.setItem('user',JSON.stringify(token))
}

const userLogout = () =>{
    localStorage.removeItem('user');
}


const deleteAccount=(user_id)=>{
    localStorage.removeItem('user');
    return axios.post(API_URL+"/api/delete-user/"+user_id).then((response)=>{
        return response.data;
    })
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    userRegister,
    userLogin,
    saveTokenInLocalStorage,
    userLogout,
    updateUser,
    updateBirth,
    deleteAccount

};