import React from 'react';
import styled from 'styled-components';
import style from '../styles';
import {Link} from 'react-router-dom';

const SButton=styled.button`
  background-color:${style.constants.colors.primary};
  cursor: pointer;
  border-radius:10px;
  padding:.5rem;
  border:2px solid ${style.constants.colors.secondary};
  width:50%;
  ${style.mixins.zoomIn('onhover',1.05)}
  &:hover{
   background-color:${style.constants.colors.secondary}; 
    border:2px solid ${style.constants.colors.primary};
   
   *{
     color:${style.constants.colors.white} !important;
   }
  }
  *{
    margin:0;
  }
`;

const Button=props => {
  return (
    <SButton style={{...props.style}} onClick={props.onClick}>
      <Link style={{width:'100%',height:'100%',textDecoration:'none'}}  to={props.href||props.to}>
        {props.children}
      </Link>
    </SButton>
  );
};

export default Button;
