import types from './types';

export const create=(video)=>{
  return (dispatch, getState, {getFirebase})=>{
    // Pause The Dispatch
    const firestore=getFirebase().firestore();
    const profile=getState().firebase.profile;
    const authorId=getState().firebase.auth.uid;
    console.log(firestore)
    firestore.collection('videos').add({
      ...video,
      authorFirstName:profile.firstName,
      authorLastName:profile.lastName,
      authorId:authorId,
      createdOn:Date.now()
    })
    .then(()=>{dispatch({type:types.CREATE_VIDEO,video})})
    .catch(err=>{dispatch({type:types.CREATE_VIDEO_ERROR,err})})
  }
}

export const update=(id,video)=>{
  return (dispatch,getState,{getFirebase})=>{
    const firestore=getFirebase().firestore();
    console.log(video)
    firestore.collection('videos').doc(id).update(video)
    .then(()=>dispatch({type:types.UPDATE_VIDEO,video}))
    .catch(err=>{dispatch({type:types.UPDATE_VIDEO_ERROR,err})});
  }
}

export const remove=(id)=>{
  return (dispatch,getState,{getFirebase})=>{
    const firestore=getFirebase().firestore();
    firestore.collection('videos').doc(id).delete()
    .then(()=>dispatch({type:types.UPDATE_VIDEO}))
    .catch(err=>{dispatch({type:types.UPDATE_VIDEO_ERROR,err})});
  }
}